import moment from "moment"
import { isEmpty } from "@zgg-core-utils/relyUtil";
export function getMomentFormat(type) {
  if (type == "year") {
    return "YYYY";
  } else if (type == "month") {
    return "YYYY-MM";
  } else if (type == "date") {
    return "YYYY-MM-DD";
  } else if (type == "date_minute") {
    return "YYYY-MM-DD HH:mm";
  } else {
    return "YYYY-MM-DD HH:mm:ss";
  }
}
export function dealWithChartValue(form, component) {
  let value
  if (form) {
    value = form[component.name];
  }
  if (isEmpty(value)) {
    return;
  }

  if (component) {
    if (component.componentName == 'date_picker') {
      let picker = component.picker

      if (value) {
        value = moment(value).format(getMomentFormat(picker));
      }

    } else if (component.componentName == 'address_input' || component.componentName == 'position_input') {
      let str = "";
      if (typeof value === 'string') {
        return value;
      }
      if (value instanceof Object) {
        if (component.componentName == 'position_input') {
          str = `${value.province}${value.city}${value.county}${value.address}`;
        } else {
          let picker = component.picker;
          if (picker == "privince") {
            str = `${value.province}`;
          } else if (picker == 'city') {
            str = `${value.province}${value.city}`;
          } else if (picker == "county") {
            str = `${value.province}${value.city}${value.county}`;
          } else if (picker == 'address') {
            str = `${value.province}${value.city}${value.county}${value.address}`;
          }
        }

      }
      return str;
    } else if (component.componentName == 'user_select') {

      let obj = form[`${component.name}UserInfo`]
      if (obj) {
        return obj.name;
      }

    } else if (component.componentName == 'user_list_select') {
      let obj = form[`${component.name}UserMap`];
      if (obj) {
        let list = Object.values(obj);
        if (list.length) {
          return list.map(item => item.name).join(",");

        }
        return ""
      } else {
        return ""
      }

    } else if (component.componentName == 'department_select') {

      let obj = form[`${component.name}DepartmentInfo`];
      if (obj) {
        return obj.name;
      }


    } else if (component.componentName == 'department_list_select') {

      let list = form[`${component.name}DepartmentInfoList`];
      if (list) {
        let v = list.map(item => item.name).join(",");

        return v
      } else {
        return ""
      }


    } else if (component.array) {
      if (value instanceof Array) {
        return value.join(',')
      }
    }

  }

  return value
}



export const chartColors = ["#5CDBD3", "#69C0FF", "#B37FEB", "#FF85C0", "#95DE64", "#FF7875", "#85A5FF", "#FF9C6E", "#FFC069", "#FFF566"]
