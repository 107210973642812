var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { ref: "fileForm", attrs: { "label-width": "0px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "attachmentIds" } },
            [
              _c(
                "el-upload",
                {
                  ref: "upload",
                  staticClass: "upload-container",
                  attrs: {
                    "show-file-list": _vm.showFileList,
                    "list-type": _vm.listType,
                    "file-list": _vm.fileList,
                    "http-request": _vm.upload,
                    "on-change": _vm.handleChange,
                    "before-upload": _vm.beforeAvatarUpload,
                    "on-preview": _vm.handlePreview,
                    "before-remove": _vm.beforeRemove,
                    "on-remove": _vm.handleRemove,
                    "on-success": _vm.handleSuccess,
                    "on-exceed": _vm.handleExceed,
                    "on-progress": _vm.handleProgress,
                    accept: _vm.accept,
                    limit: _vm.limit ? _vm.limit : 0,
                    drag: "",
                    action: "",
                    multiple: _vm.multiple,
                  },
                  on: {
                    "update:fileList": function ($event) {
                      _vm.fileList = $event
                    },
                    "update:file-list": function ($event) {
                      _vm.fileList = $event
                    },
                  },
                },
                [
                  !_vm.$slots.default
                    ? _c("div", [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v(" 将文件拖到此处，或"),
                          _c("em", [_vm._v("点击上传")]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._t("default"),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }