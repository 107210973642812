// import Vue from 'vue'
// import router from '../router'
//
// // import * as Sentry from "@sentry/vue";
// // import { BrowserTracing } from "@sentry/tracing";
//
//
//
// import { DSN, RELEASE_VERSION, TRACE_PROPAGATION_TARGETS } from "./config";
//
//
//
// /**
//  * 初始化sentry监控
//  */
// export function sentryInit(){
//   console.log("开始初始化sentryInit")
//   console.log("sentryInit",DSN,RELEASE_VERSION,TRACE_PROPAGATION_TARGETS)
//   Sentry.init({
//     Vue,
//     dsn: DSN,
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracePropagationTargets: ["localhost", /^\//,...TRACE_PROPAGATION_TARGETS],
//       }),
//     ],
//     tracesSampleRate: 1.0,
//     release: RELEASE_VERSION
//
//   });
// }
//
//
//
