var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attachment-wrap" },
    _vm._l(_vm.attachmentList, function (attachment, index) {
      return _c("div", { key: index, staticClass: "attachment-item" }, [
        _c(
          "div",
          { staticClass: "attachment-icon" },
          [
            _vm.checkFileImage(attachment.extension)
              ? _c("el-image", {
                  ref: "elImg_" + index,
                  refInFor: true,
                  staticClass: "attachment-image",
                  attrs: {
                    "z-index": 99999,
                    src:
                      attachment.url +
                      "?x-oss-process=image/resize,m_lfit,h_100,w_100",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.reviewImg(index)
                    },
                  },
                })
              : _vm._e(),
            !_vm.checkFileImage(attachment.extension)
              ? _c("SvgIcon", {
                  staticClass: "attachment-svg",
                  attrs: {
                    "icon-class": _vm.getIconClassByExtension(
                      attachment.extension
                    ),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "attachment-info" }, [
          _c("div", { staticClass: "attachment-name" }, [
            _c("span", { staticClass: "name" }, [
              _vm._v(
                _vm._s(attachment.originalName) +
                  "." +
                  _vm._s(attachment.extension)
              ),
            ]),
            _c("span", { staticClass: "size" }, [
              _vm._v(
                "（" + _vm._s(_vm._f("formatFileSize")(attachment.size)) + "）"
              ),
            ]),
          ]),
          _c("div", { staticStyle: { margin: "10px 0px" } }, [
            _c("span", [_vm._v(" " + _vm._s(attachment.createdTime) + " ")]),
            _c(
              "div",
              {
                staticClass: "attachment-tool",
                staticStyle: { display: "inline-block", "margin-left": "10px" },
              },
              [
                _vm.checkoutDocument(attachment)
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "primary", icon: "el-icon-view" },
                        on: {
                          click: function ($event) {
                            return _vm.handleOnlinePreview(attachment)
                          },
                        },
                      },
                      [_vm._v("在线预览 ")]
                    )
                  : _vm._e(),
                _c(
                  "el-link",
                  {
                    attrs: { type: "primary", icon: "el-icon-edit" },
                    on: {
                      click: function ($event) {
                        return _vm.rename(attachment)
                      },
                    },
                  },
                  [_vm._v("重命名 ")]
                ),
                _vm._t("default", null, { row: attachment, index: index }),
              ],
              2
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }