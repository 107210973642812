<template>
  <div
    class="el-form-item tabs"
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    @mousedown="mousedown"
  >
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane
        v-for="(item, index0) in data.components"
        :key="index0"
        :label="item.title"
        :name="index0 + ''"
      >
        <draggable
          :list="item.components"
          :group="colGroup2"
          animation="700"
          :scroll-sensitivity="500"
          fallback-tolerance="500"
          :scroll-speed="1000"
          style="min-height: 200px; display: flex; flex-wrap: wrap"
          :forceFallback="false"
          @choose="chooseDrag"
          @end="dragEnd"
          @add="onAdd"
        >
          <template v-for="(col, cIndex) in item.components">
            <reference-data
              :ref="`cpt_${col.key}`"
              :key="`${col.key}`"
              v-if="col.componentName == 'reference_data'"
              :index="cIndex"
              :data="col"
              :style="handleItemWidth(col)"
              class="custom-form-item"
              :isDrag="isDrag"
              :class="{
                'is-checked': currentKey == col.key,
                'copy-move': col.isCopyMove,
              }"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @click.native="changeCurrNode(col, $event)"
              @changeCurrNode="changeCurrNode"
            ></reference-data>
            <edit-grid
              v-else-if="col.componentName == 'grid'"
              :key="col.key"
              :ref="`cpt_${col.key}`"
              :component="col"
              :index="cIndex"
              class="custom-form-item"
              :put="put"
              :isDrag="isDrag"
              :cols="cols"
              :getError="getError"
              :buildCopyItem="buildCopyItem"
              :currentKey="currentKey"
              :pasteSource="pasteSource"
              @changeCurrNode="changeCurrNode"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @choose="chooseDrag"
              @changePasteSource="changePasteSource"
              :class="{
                'is-checked': currentKey == col.key,
                'is-error': !!getError(col) && isSave,
                'copy-move': col.isCopyMove,
              }"
            ></edit-grid>
            <component-item
              v-else
              :ref="`cpt_${col.key}`"
              :key="col.key"
              :index="cIndex"
              :data="col"
              :put="put"
              :style="handleItemWidth(col)"
              class="custom-form-item"
              :isDrag="isDrag"
              :class="{
                'is-checked': currentKey == col.key,
                'copy-move': col.isCopyMove,
              }"
              @removeItem="removeTabItem"
              @copyItem="copyTabItem"
              @click.native="changeCurrNode(col, $event)"
              @changeCurrNode="changeCurrNode"
            ></component-item>
          </template>
        </draggable>
      </el-tab-pane>
    </el-tabs>

    <div class="action-item" v-show="actionVisible">
      <i
        @click="copyItem"
        @mousedown.stop=""
        class="el-icon-copy-document"
        title="复制"
        style="margin-right: 5px"
      ></i>
      <i
        @click="removeItem"
        @mousedown.stop=""
        title="删除"
        class="el-icon-delete"
      ></i>
    </div>
  </div>
</template>
<script>
import Draggable from "vuedraggable";
export default {
  components: { Draggable },
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
    cols: {
      type: Number,
      default() {
        return 1;
      },
    },
    currentKey: String,
    put: Boolean,
    getError: Function,
    isSave: Boolean,
    pasteSource: [Object, Array],
  },
  computed: {},
  data() {
    return {
      actionVisible: false,
      activeName: 0,
      colGroup2: {
        name: "column",
      },
    };
  },
  watch: {
    activeName: {
      handler(val) {
        this.$set(this.data, "activeName", val);
      },
    },
  },
  methods: {
    /**
     * 处理表单项宽度
     *  如果表单项单项已经设置了宽度，则获取配置的数据，如果没有配置单项，则获取表单全局设置的列数来计算宽度
  
     * @param formItemConfig
     * @returns {string}
     */
    handleItemWidth(formItemConfig) {
      if (!formItemConfig.extParam || !formItemConfig.extParam.customWidth) {
        return `width:${100 / this.cols}%;`;
      } else {
        return `width:${100 * eval(formItemConfig.extParam.customWidth)}%;`;
      }
    },
    onAdd(e) {
      let addComponent =
        this.data.components[this.activeName].components[e.newIndex];
      if (addComponent.componentName == "tab") {
        this.$message.warning("不允许将标签页置入标签页中");
        this.$delete(
          this.data.components[this.activeName].components,
          e.newIndex,
        );
      } else {
        this.$emit(
          "changeCurrNode",
          this.data.components[this.activeName].components[e.newIndex],
        );
      }
    },
    dragEnd(e) {
      this.$emit(
        "changeCurrNode",
        this.data.components[this.activeName].components[e.newIndex],
      );
    },
    chooseDrag() {
      this.$emit("choose");
    },
    changeCurrNode(node, event) {
      this.$emit("changeCurrNode", node, event);
    },

    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = true;
      } else {
        this.$emit("mouseenter", this.index, event);
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      } else {
        this.$emit("mouseleave", this.index, event);
      }
    },
    mousedown(event) {
      let classList = event.target.classList;

      if (classList.contains("el-tabs__item")) {
        return;
      }
      this.$emit("mousedown", this.index, event);
    },
    changePasteSource(obj) {
      this.$emit("changePasteSource", obj);
    },
    removeTabItem(index) {
      if (index >= 0) {
        this.data.components[this.activeName].components.splice(index, 1);
      }
      this.$emit("removeItem", -1);
    },
    copyTabItem(index) {
      let obj = this._.cloneDeep(
        this.data.components[this.activeName].components[index],
      );
      this.buildCopyItem(obj);
      this.data.components[this.activeName].components.splice(
        index + 1,
        0,
        obj,
      );
    },
    buildCopyItem(obj) {
      delete obj.name;
      delete obj.key;
      let uuid = this.getUuid();
      obj.key = uuid;
      if (obj.form) {
        obj.name = uuid;
      }
      if (obj.components) {
        obj.components.forEach((item) => {
          this.buildCopyItem(item);
        });
      }
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  clear: both;

  ::v-deep {
    .el-tabs__content {
      border-color: #e4e7ed;
      border-width: 0px 1px 1px;
      border-style: solid;
      padding-top: 15px;
    }

    .el-tabs__header {
      margin-bottom: 0px !important;
    }
  }
}

.action-item {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  border-radius: 4px;
}
.custom-form-item {
  position: relative;
  float: left;
  &.is-checked {
    // border: #409eff dashed 1px;

    background-color: rgba($color: #409eff, $alpha: 0.1) !important;
    border-radius: 5px;
  }

  ::v-deep {
    .el-form-item__content {
      position: unset;
    }
  }
}
</style>
