var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.componentName == "html"
    ? _c(
        "div",
        {
          staticStyle: { position: "relative", width: "max-content" },
          on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
        },
        [
          _c("div", { domProps: { innerHTML: _vm._s(_vm.data.description) } }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.actionVisible,
                  expression: "actionVisible",
                },
              ],
              staticClass: "action-item",
            },
            [
              _c("i", {
                staticClass: "el-icon-copy-document",
                staticStyle: { "margin-right": "5px", cursor: "pointer" },
                attrs: { title: "复制" },
                on: {
                  click: _vm.copyItem,
                  mousedown: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
              _c("i", {
                staticClass: "el-icon-delete",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "删除" },
                on: {
                  click: _vm.removeItem,
                  mousedown: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
            ]
          ),
        ]
      )
    : _c(
        "el-form-item",
        {
          class: {
            "un-label": !_vm.data.form && _vm.data.componentName != "form_data",
          },
          attrs: {
            label:
              (_vm.data.form || _vm.data.componentName == "form_data") &&
              !_vm.data.hiddenTitle
                ? _vm.label
                : "",
            required: !!_vm.data.required,
          },
          nativeOn: {
            mouseenter: function ($event) {
              return _vm.mouseenter.apply(null, arguments)
            },
            mouseleave: function ($event) {
              return _vm.mouseleave.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.actionVisible,
                  expression: "actionVisible",
                },
              ],
              staticClass: "action-item",
            },
            [
              _c("i", {
                staticClass: "el-icon-copy-document",
                staticStyle: { "margin-right": "5px", cursor: "pointer" },
                attrs: { title: "复制" },
                on: {
                  click: _vm.copyItem,
                  mousedown: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
              _c("i", {
                staticClass: "el-icon-delete",
                staticStyle: { cursor: "pointer" },
                attrs: { title: "删除" },
                on: {
                  click: _vm.removeItem,
                  mousedown: function ($event) {
                    $event.stopPropagation()
                  },
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.data.hidden,
                  expression: "data.hidden",
                },
              ],
              staticClass: "action-item__hidden",
            },
            [_c("svg-icon", { attrs: { "icon-class": "eye" } })],
            1
          ),
          _vm.data.componentName == "input" ||
          _vm.data.componentName == "input_number" ||
          _vm.data.componentName == "mobile_input" ||
          _vm.data.componentName == "object" ||
          _vm.data.componentName == "object_array"
            ? _c("el-input", {
                attrs: { placeholder: _vm.data.placeHolder, disabled: "" },
              })
            : _vm.data.componentName == "text_area"
            ? _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: _vm.data.placeHolder,
                  disabled: "",
                },
              })
            : _vm.data.componentName == "date_picker"
            ? _c("el-date-picker", {
                attrs: {
                  type: _vm.data.picker,
                  placeholder: _vm.data.placeHolder,
                  disabled: "",
                },
              })
            : _vm.data.componentName == "select"
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.data.placeHolder,
                    value: _vm.selectValue,
                    disabled: "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.data.optionSource == "custom"
                    ? _vm._l(_vm.data.customOptions, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm.data.componentName == "select_checkbox"
            ? _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: _vm.data.placeHolder,
                    value: _vm.selectValue,
                    disabled: "",
                    multiple: "",
                    filterable: "",
                    "collapse-tags": "",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.clickItem.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.data.optionSource == "custom"
                    ? _vm._l(_vm.data.customOptions, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm.data.componentName == "radio_group"
            ? _c(
                "el-radio-group",
                { staticClass: "custom-radio", attrs: { disabled: "" } },
                [
                  _vm.data.optionSource == "custom"
                    ? _vm._l(_vm.data.customOptions, function (item) {
                        return _c(
                          "el-radio",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label) + " ")]
                        )
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm.data.componentName == "checkbox_group"
            ? _c(
                "el-checkbox-group",
                {
                  staticClass: "custom-checkbox",
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.checkboxValue,
                    callback: function ($$v) {
                      _vm.checkboxValue = $$v
                    },
                    expression: "checkboxValue",
                  },
                },
                [
                  _vm.data.optionSource == "custom"
                    ? _vm._l(_vm.data.customOptions, function (item) {
                        return _c(
                          "el-checkbox",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.label) + " ")]
                        )
                      })
                    : _vm._e(),
                ],
                2
              )
            : _vm.data.componentName == "divider"
            ? _c(
                "my-divider",
                {
                  attrs: {
                    "line-type": _vm.data.lineType,
                    "title-align": _vm.data.titleAlign,
                    dashed: _vm.data.dashed,
                    "line-color": _vm.data.lineColor,
                  },
                },
                [_vm._v(_vm._s(_vm.data.title) + " ")]
              )
            : _vm.data.componentName == "attachment_uploader" ||
              _vm.data.componentName == "image_uploader"
            ? _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                _vm._v(
                  _vm._s(
                    _vm.data.componentName == "attachment_uploader"
                      ? "上传附件"
                      : "上传图片"
                  ) + " "
                ),
              ])
            : _vm.data.componentName == "json_form"
            ? _c(
                "div",
                { staticClass: "json-form" },
                [
                  _c(
                    "div",
                    { staticClass: "grid", staticStyle: { width: "50px" } },
                    [
                      _c("div", { staticClass: "th" }),
                      _c(
                        "div",
                        {
                          staticClass: "td",
                          staticStyle: { "text-align": "center" },
                        },
                        [_vm._v("1")]
                      ),
                    ]
                  ),
                  _c(
                    "el-scrollbar",
                    { staticStyle: { flex: "1", width: "0" } },
                    [
                      _c(
                        "div",
                        { staticClass: "grid-flex" },
                        [
                          _c(
                            "draggable",
                            {
                              staticClass: "grid-drag",
                              attrs: {
                                list: _vm.data.components,
                                group: _vm.colGroup2,
                                animation: "300",
                                filter: ".row-empty",
                              },
                              on: { add: _vm.onAdd },
                            },
                            [
                              _vm._l(
                                _vm.data.components,
                                function (itemChild, childIndex) {
                                  return _c("sub-component", {
                                    key: itemChild.key,
                                    staticClass: "grid",
                                    class: {
                                      "is-checked":
                                        _vm.currentKey == itemChild.key,
                                    },
                                    attrs: {
                                      index: childIndex,
                                      data: itemChild,
                                    },
                                    on: {
                                      removeItem: _vm.removeSubcomponent,
                                      copyItem: _vm.copySubcomponent,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.checkComponent(
                                          childIndex,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "row-empty",
                                  class: {
                                    "row-half":
                                      _vm.data.components &&
                                      _vm.data.components.length > 0,
                                  },
                                },
                                [_vm._v(" 从左侧拖拽来添加字段 ")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm.data.componentName == "sign_input"
            ? _c("sign")
            : _vm.data.componentName == "address_input"
            ? _c("my-address", {
                attrs: {
                  "area-tree": _vm.areaTree,
                  picker: _vm.data.picker,
                  disabled: "",
                },
              })
            : _vm.data.componentName == "form_data"
            ? _c(
                "div",
                [
                  _c(
                    "el-table",
                    { attrs: { data: [] } },
                    [
                      _vm._l(_vm.data.columns, function (col, index) {
                        return [
                          col.field.componentName != "reference_data"
                            ? _c(
                                "el-table-column",
                                {
                                  key: index,
                                  attrs: {
                                    align: col.align,
                                    label: col.title,
                                    width:
                                      col.components &&
                                      col.components.length &&
                                      col.width
                                        ? col.width
                                        : "",
                                  },
                                },
                                [
                                  col.field.componentName == "json_form" &&
                                  col.subColumns &&
                                  col.subColumns.length
                                    ? _vm._l(
                                        col.subColumns,
                                        function (subCol, sIndex) {
                                          return _c("el-table-column", {
                                            key: sIndex,
                                            attrs: {
                                              align: subCol.align,
                                              label: subCol.title,
                                              width: subCol.width
                                                ? subCol.width
                                                : "",
                                            },
                                          })
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _vm._l(col.subColumns, function (sub) {
                                return _c(
                                  "el-table-column",
                                  {
                                    key: sub.field.component.name,
                                    attrs: {
                                      align: sub.align,
                                      label: sub.title,
                                    },
                                  },
                                  [
                                    sub.field.componentName == "json_form" &&
                                    sub.field.component.components &&
                                    sub.field.component.components.length
                                      ? [
                                          _vm._l(
                                            sub.field.component.components,
                                            function (subCol, sIndex) {
                                              return [
                                                subCol.componentName !=
                                                "reference_data"
                                                  ? _c("el-table-column", {
                                                      key:
                                                        subCol.key +
                                                        "_" +
                                                        sIndex,
                                                      attrs: {
                                                        align: subCol.align,
                                                        label: subCol.title,
                                                        width: subCol.width
                                                          ? subCol.width
                                                          : "",
                                                      },
                                                    })
                                                  : _vm._l(
                                                      subCol.columns,
                                                      function (
                                                        child,
                                                        childIndex
                                                      ) {
                                                        return _c(
                                                          "el-table-column",
                                                          {
                                                            key:
                                                              child.field.name +
                                                              "_" +
                                                              childIndex,
                                                            attrs: {
                                                              align:
                                                                child.align,
                                                              label:
                                                                child.title,
                                                            },
                                                          }
                                                        )
                                                      }
                                                    ),
                                              ]
                                            }
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm.data.componentName == "sn_input"
            ? _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "自动生成无需填写",
                      disabled: "",
                      readonly: "",
                    },
                  }),
                ],
                1
              )
            : _vm.data.componentName == "user_select"
            ? _c(
                "div",
                {
                  staticClass: "outer-selected-user__empty-wrap",
                  staticStyle: { height: "40px" },
                },
                [_vm._v(" 选择成员 ")]
              )
            : _vm.data.componentName == "user_list_select"
            ? _c(
                "div",
                {
                  staticClass: "outer-selected-user__empty-wrap",
                  staticStyle: { height: "40px" },
                },
                [_vm._v(" 选择成员 ")]
              )
            : _vm.data.componentName == "department_select"
            ? _c(
                "div",
                {
                  staticClass: "outer-selected-user__empty-wrap",
                  staticStyle: { height: "40px" },
                },
                [_vm._v(" 选择部门 ")]
              )
            : _vm.data.componentName == "department_list_select"
            ? _c(
                "div",
                {
                  staticClass: "outer-selected-user__empty-wrap",
                  staticStyle: { height: "40px" },
                },
                [_vm._v(" 选择部门 ")]
              )
            : _vm.data.componentName == "position_input"
            ? _c("div", { staticClass: "fui_location" }, [
                _c("i", { staticClass: "el-icon-location-information" }),
                _c("span", [_vm._v("获取定位")]),
              ])
            : _vm.data.componentName == "include_form"
            ? _c("div", { staticClass: "include_form" }, [
                _vm._v(" 嵌入表单区域 "),
              ])
            : _vm.data.componentName == "parent_data"
            ? _c("el-cascader", {
                staticStyle: { width: "100%" },
                attrs: { options: [], disabled: "" },
              })
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }