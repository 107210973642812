
/**
 * 低代码表格列表不同数据类型的最小宽度定义枚举
 */
 export function getTableColumnWidthByColumn(field) {
    let {componentName, name} = field

    const componentNames = {
        "input": 150,
        "text_area": 300,
        "input_number": 150,
        "date_picker": 160,
        "radio_group": 150,
        "checkbox_group": 150,
        "select": 150,
        "json_form": '',
        "address_input": 300,
        "image_uploader": 150,
        "attachment_uploader": 150,
        "sign_input": 150,
        "form_data": 150,
        "reference_data": 150,
        "sn_input": 150,
        "user_select": 150,
        "user_list_select": 250,
        'department_select': 150,
        "department_list_select": 250
    }
    const fieldName = {
        "createdTime": 160,
        "createrName": 150,
        "_id": 60,
        "_index": 60
    }
    return (componentNames[componentName] || fieldName[name] || 150) + 'px'

}

/**
 * 返回如果是嵌套路由，回到父路由
 */
export function back() {
  let { matched } = this.$router.history.current;
  if (matched.length >= 2) {
    let parentPath = matched[matched.length - 2].path;
    this.$router.push({ path: parentPath });
  } else {
    this.$router.back();
  }
}
