var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, "span-method": _vm.spanMethod },
        },
        _vm._l(_vm.column.columns, function (col, index) {
          return _c(
            "el-table-column",
            {
              key: index,
              attrs: {
                align: col.align,
                label: col.title,
                width:
                  col.field.componentName == "json_form" &&
                  col.subColumns &&
                  col.subColumns.length &&
                  col.width
                    ? col.width
                    : "",
                prop:
                  col.subColumns && col.subColumns.length ? "" : col.field.name,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        col.field.componentName == "attachment_uploader" ||
                        col.field.componentName == "image_uploader"
                          ? _c("attachment-thumb", {
                              attrs: {
                                "file-type":
                                  col.field.componentName == "image_uploader"
                                    ? "image"
                                    : "",
                                list: row[col.field.name],
                              },
                            })
                          : col.field.componentName == "sign_input" &&
                            row[col.field.name]
                          ? _c("el-image", {
                              staticClass: "code-image",
                              attrs: {
                                src:
                                  "data:image/png;base64," +
                                  row[col.field.name].signText,
                                fit: "scale-down",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.reviewSign(row[col.field.name])
                                },
                              },
                            })
                          : _c("span", [
                              _vm._v(_vm._s(_vm.getValue(row, col))),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            },
            [
              col.field.componentName == "json_form" &&
              col.subColumns &&
              col.subColumns.length
                ? _vm._l(col.subColumns, function (subCol, sIndex) {
                    return _c("el-table-column", {
                      key: sIndex,
                      attrs: {
                        align: subCol.align,
                        label: subCol.title,
                        width: subCol.width ? subCol.width : "",
                        prop: subCol.field.name,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                subCol.field.componentName ==
                                  "attachment_uploader" ||
                                subCol.field.componentName == "image_uploader"
                                  ? _c("attachment-thumb", {
                                      attrs: {
                                        "file-type":
                                          col.field.componentName ==
                                          "image_uploader"
                                            ? "image"
                                            : "",
                                        list:
                                          scope.row &&
                                          scope.row[subCol.field.name]
                                            ? scope.row[subCol.field.name]
                                            : [],
                                      },
                                    })
                                  : subCol.field.componentName ==
                                      "sign_input" &&
                                    scope.row[subCol.field.name]
                                  ? _c("el-image", {
                                      staticClass: "code-image",
                                      attrs: {
                                        src:
                                          "data:image/png;base64," +
                                          scope.row[subCol.field.name].signText,
                                        fit: "scale-down",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.reviewSign(
                                            scope.row[subCol.field.name]
                                          )
                                        },
                                      },
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.getValue(scope.row, subCol))
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  })
                : _vm._e(),
            ],
            2
          )
        }),
        1
      ),
      _vm.column.queryObject.pageSize > 1 &&
      (_vm.column.hasPageTool == undefined || _vm.column.hasPageTool)
        ? _c(
            "div",
            { staticClass: "right" },
            [
              _c("pagination", {
                attrs: {
                  total: _vm.page.totalRow,
                  page: _vm.page.pageNumber,
                  limit: _vm.page.pageSize,
                  pageSizes: [],
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.page, "pageNumber", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.page, "pageSize", $event)
                  },
                  pagination: _vm.loadingList,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }