import Vue from "vue";

import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import ElementUI from "element-ui";
import "./styles/element-variables.scss";
import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n
import moment from "moment";
import "@/styles/index.scss"; // global css

// 对 JS 语法做一些补丁，规避一些兼容问题和语法陷阱
import "@/zgg-core/polyfill";

import VueCodeMirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";
Vue.use(VueCodeMirror);

import App from "./App";
import store from "./store";
import router from "./router";

import "@/icons"; // icon
import "@/permission"; // permission control

import request from "@/utils/request";
import _ from "lodash";

import "default-passive-events";
import VueDraggableResizable from "vue-draggable-resizable";
import MyDivider from "@/components/divider";
import zggCore from "@zgg-core-utils";
import { isEmpty } from "@zgg-core-utils/relyUtil";
if (zggCore) {
  Vue.use(zggCore);
}

// 可选择导入默认样式
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import FormItem from "@/views/lowCode/form/component/FormItem";
import EditTabs from "@/views/lowCode/form/component/EditTabs";
import componentItem from "@/views/lowCode/form/componentItem";
import ReferenceData from "@/views/lowCode/form/component/ReferenceData";
import FormTplItem from "@/views/lowCode/form/component/FormTplItem";
import PreviewTabs from "@/views/lowCode/form/component/PreviewTabs";
import GridView from "@/views/lowCode/form/component/GridView";
import { sentryInit } from "@/sentry";
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.component("my-divider", MyDivider);
Vue.component("form-item", FormItem);
Vue.component("edit-tabs", EditTabs);
Vue.component("component-item", componentItem);
Vue.component("ReferenceData", ReferenceData);
Vue.component("FormTplItem", FormTplItem);
Vue.component("PreviewTabs", PreviewTabs);
Vue.component("GridView", GridView);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === "production") {
  // const { sentryInit } = require('./sentry/index')
  // sentryInit()
}

Vue.prototype.$http = request;
_.isEmpty = isEmpty;
Vue.prototype._ = _;
Vue.prototype.getUuid = () => {
  var d = new Date().getTime();
  // 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  var uuid = "xxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  uuid =
    String.fromCharCode(Math.floor(Math.random() * 26) + "a".charCodeAt(0)) +
    uuid;
  return uuid;
};
Vue.use(ElementUI, { locale });

Vue.directive("loadmore", {
  bind(el, binding) {
    // 获取element-ui定义好的scroll盒子
    const SELECTWRAP_DOM = el.querySelector(
      ".el-select-dropdown .el-select-dropdown__wrap",
    );
    SELECTWRAP_DOM.addEventListener("scroll", function () {
      const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight;
      if (CONDITION) {
        binding.value();
      }
    });
  },
});
Vue.config.productionTip = false;
Vue.filter("formatDate", function (value) {
  return moment(value).format("YYYY-MM-DD HH:mm:ss");
});
Vue.filter("formatFileSize", function (value) {
  var fileSizeByte = value;
  var fileSizeMsg = "";
  if (fileSizeByte < 1048576)
    fileSizeMsg = (fileSizeByte / 1024).toFixed(2) + "KB";
  else if (fileSizeByte == 1048576) fileSizeMsg = "1MB";
  else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824)
    fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(2) + "MB";
  else if (fileSizeByte > 1048576 && fileSizeByte == 1073741824)
    fileSizeMsg = "1GB";
  else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776)
    fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(2) + "GB";
  else fileSizeMsg = "文件超过1TB";
  return fileSizeMsg;
});

new Vue({
  beforeCreate() {
    Vue.prototype.$bus = this;
    Vue.prototype.environment = window.environment;
  },
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
