import { render, staticRenderFns } from "./JsonForm.vue?vue&type=template&id=ec584ede&scoped=true"
import script from "./JsonForm.vue?vue&type=script&lang=js"
export * from "./JsonForm.vue?vue&type=script&lang=js"
import style0 from "./JsonForm.vue?vue&type=style&index=0&id=ec584ede&lang=scss&scoped=true"
import style1 from "./JsonForm.vue?vue&type=style&index=1&id=ec584ede&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec584ede",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/prj-web/git/pv3/dev-web-admin/zhiguangong-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ec584ede')) {
      api.createRecord('ec584ede', component.options)
    } else {
      api.reload('ec584ede', component.options)
    }
    module.hot.accept("./JsonForm.vue?vue&type=template&id=ec584ede&scoped=true", function () {
      api.rerender('ec584ede', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/lowCode/form/component/JsonForm.vue"
export default component.exports