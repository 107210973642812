var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c(
        "div",
        { staticClass: "tabs" },
        [
          _c(
            "div",
            { staticStyle: { color: "#ffffff", "font-size": "12px" } },
            [_vm._v("tabs")]
          ),
          _vm.data.components && _vm.data.components.length
            ? [
                _c(
                  "el-tabs",
                  {
                    attrs: { type: "card" },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _vm._l(_vm.data.components, function (item, index) {
                      return [
                        _vm.isShowComponent(item)
                          ? _c(
                              "el-tab-pane",
                              {
                                key: index,
                                attrs: { label: item.title, name: index + "" },
                              },
                              [
                                _vm._l(item.components, function (col, cIndex) {
                                  return [
                                    _c("form-tpl-item", {
                                      key: cIndex,
                                      attrs: {
                                        isShowComponent: _vm.isShowComponent,
                                        cols: _vm.cols,
                                        form: _vm.form,
                                        item: col,
                                        formType: _vm.formType,
                                        "source-list": _vm.sourceList,
                                        "area-tree": _vm.areaTree,
                                      },
                                      on: {
                                        changeFormData: _vm.changeFormData,
                                        getMetaOptions: _vm.getMetaOptions,
                                      },
                                    }),
                                  ]
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }