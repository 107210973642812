var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.componentName == "reference_data" &&
    _vm.isShowComponent(_vm.item)
    ? _c("reference-data-view", {
        attrs: {
          item: _vm.item,
          form: _vm.form,
          rules: _vm.rules,
          cols: _vm.cols,
        },
      })
    : _vm.item.componentName == "tab"
    ? _c("preview-tabs", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.item.hidden,
            expression: "!item.hidden",
          },
        ],
        attrs: {
          cols: _vm.cols,
          form: _vm.form,
          data: _vm.item,
          formType: _vm.formType,
          "source-list": _vm.sourceList,
          "area-tree": _vm.areaTree,
          isShowComponent: _vm.isShowComponent,
        },
        on: {
          changeFormData: _vm.changeFormData,
          getMetaOptions: _vm.getMetaOptions,
        },
      })
    : _vm.item.componentName == "grid" && _vm.isShowComponent(_vm.item)
    ? _c("grid-view", {
        attrs: {
          cols: _vm.cols,
          form: _vm.form,
          grid: _vm.item,
          "source-list": _vm.sourceList,
          "area-tree": _vm.areaTree,
          formType: _vm.formType,
          isShowComponent: _vm.isShowComponent,
        },
        on: {
          changeFormData: _vm.changeFormData,
          getMetaOptions: _vm.getMetaOptions,
        },
      })
    : _vm.item.componentName == "html" && _vm.isShowComponent(_vm.item)
    ? _c("div", {
        staticStyle: { float: "left" },
        style: _vm.handleItemWidth(_vm.item),
        domProps: { innerHTML: _vm._s(_vm.item.description) },
      })
    : _vm.item.componentName == "include_form" && _vm.isShowComponent(_vm.item)
    ? _c("include-form", {
        attrs: { item: _vm.item, areaTree: _vm.areaTree, form: _vm.form },
      })
    : !_vm.arr.includes(_vm.item.componentName) && _vm.isShowComponent(_vm.item)
    ? _c(
        "el-form-item",
        {
          class: { "un-label": !_vm.isShowLabel(_vm.item) },
          style: _vm.handleItemWidth(_vm.item),
          attrs: {
            label: _vm.isShowLabel(_vm.item) ? _vm.item.title : "",
            prop: _vm.item.name,
            rules: _vm.rules,
          },
        },
        [
          _c("div", {
            staticStyle: { color: "#91a1b7" },
            domProps: { innerHTML: _vm._s(_vm.item.description) },
          }),
          _vm.item.componentName == "json_form"
            ? _c("json-form", {
                attrs: {
                  item: _vm.item,
                  form: _vm.form,
                  formType: _vm.formType,
                  "source-list": _vm.sourceList,
                  "area-tree": _vm.areaTree,
                },
                on: {
                  changeFormData: _vm.changeFormData,
                  getMetaOptions: _vm.getMetaOptions,
                },
              })
            : _c("form-item", {
                attrs: {
                  item: _vm.item,
                  form: _vm.form,
                  watchForm: _vm.form,
                  "source-list": _vm.sourceList,
                  "area-tree": _vm.areaTree,
                },
                on: {
                  changeFormData: _vm.changeFormData,
                  getMetaOptions: _vm.getMetaOptions,
                },
              }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }