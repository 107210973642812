<template>
  <div
    @mouseenter="mouseenter"
    @mouseleave="mouseleave"
    v-if="data.componentName == 'html'"
    style="position: relative; width: max-content"
  >
    <div v-html="data.description"></div>
    <div class="action-item" v-show="actionVisible">
      <i
        @click="copyItem"
        @mousedown.stop=""
        class="el-icon-copy-document"
        title="复制"
        style="margin-right: 5px; cursor: pointer"
      ></i>
      <i
        @click="removeItem"
        @mousedown.stop=""
        title="删除"
        class="el-icon-delete"
        style="cursor: pointer"
      ></i>
    </div>
  </div>

  <el-form-item
    v-else
    @mouseenter.native="mouseenter"
    @mouseleave.native="mouseleave"
    :label="
      (data.form || data.componentName == 'form_data') && !data.hiddenTitle
        ? label
        : ''
    "
    :required="!!data.required"
    :class="{ 'un-label': !data.form && data.componentName != 'form_data' }"
  >
    <div class="action-item" v-show="actionVisible">
      <i
        @click="copyItem"
        @mousedown.stop=""
        class="el-icon-copy-document"
        title="复制"
        style="margin-right: 5px; cursor: pointer"
      ></i>
      <i
        @click="removeItem"
        @mousedown.stop=""
        title="删除"
        class="el-icon-delete"
        style="cursor: pointer"
      ></i>
    </div>
    <div class="action-item__hidden" v-show="data.hidden">
      <svg-icon icon-class="eye" />
    </div>

    <el-input
      v-if="
        data.componentName == 'input' ||
        data.componentName == 'input_number' ||
        data.componentName == 'mobile_input' ||
        data.componentName == 'object' ||
        data.componentName == 'object_array'
      "
      :placeholder="data.placeHolder"
      disabled
    >
    </el-input>
    <el-input
      v-else-if="data.componentName == 'text_area'"
      type="textarea"
      :placeholder="data.placeHolder"
      disabled
    ></el-input>
    <el-date-picker
      v-else-if="data.componentName == 'date_picker'"
      :type="data.picker"
      :placeholder="data.placeHolder"
      disabled
    ></el-date-picker>
    <el-select
      v-else-if="data.componentName == 'select'"
      :placeholder="data.placeHolder"
      :value="selectValue"
      disabled
      style="width: 100%"
      @click.native="clickItem"
    >
      <template v-if="data.optionSource == 'custom'">
        <el-option
          v-for="(item, index) in data.customOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </template>
    </el-select>
    <el-select
      v-else-if="data.componentName == 'select_checkbox'"
      :placeholder="data.placeHolder"
      :value="selectValue"
      disabled
      style="width: 100%"
      @click.native="clickItem"
      multiple
      filterable
      collapse-tags
    >
      <template v-if="data.optionSource == 'custom'">
        <el-option
          v-for="(item, index) in data.customOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </template>
    </el-select>
    <el-radio-group
      class="custom-radio"
      v-else-if="data.componentName == 'radio_group'"
      disabled
    >
      <template v-if="data.optionSource == 'custom'">
        <el-radio
          v-for="item in data.customOptions"
          :key="item.value"
          :label="item.value"
          >{{ item.label }}
        </el-radio>
      </template>
    </el-radio-group>
    <el-checkbox-group
      v-model="checkboxValue"
      v-else-if="data.componentName == 'checkbox_group'"
      disabled
      class="custom-checkbox"
    >
      <template v-if="data.optionSource == 'custom'">
        <el-checkbox
          v-for="item in data.customOptions"
          :label="item.value"
          :key="item.value"
          >{{ item.label }}
        </el-checkbox>
      </template>
    </el-checkbox-group>
    <my-divider
      v-else-if="data.componentName == 'divider'"
      :line-type="data.lineType"
      :title-align="data.titleAlign"
      :dashed="data.dashed"
      :line-color="data.lineColor"
      >{{ data.title }}
    </my-divider>

    <el-button
      v-else-if="
        data.componentName == 'attachment_uploader' ||
        data.componentName == 'image_uploader'
      "
      type="primary"
      size="mini"
      >{{
        data.componentName == "attachment_uploader" ? "上传附件" : "上传图片"
      }}
    </el-button>

    <div v-else-if="data.componentName == 'json_form'" class="json-form">
      <div class="grid" style="width: 50px">
        <div class="th"></div>
        <div class="td" style="text-align: center">1</div>
      </div>

      <el-scrollbar style="flex: 1; width: 0">
        <div class="grid-flex">
          <draggable
            :list="data.components"
            :group="colGroup2"
            animation="300"
            class="grid-drag"
            filter=".row-empty"
            @add="onAdd"
          >
            <sub-component
              v-for="(itemChild, childIndex) in data.components"
              :key="itemChild.key"
              :index="childIndex"
              :data="itemChild"
              class="grid"
              :class="{
                'is-checked': currentKey == itemChild.key,
              }"
              @removeItem="removeSubcomponent"
              @copyItem="copySubcomponent"
              @click.native="checkComponent(childIndex, $event)"
            ></sub-component>
            <div
              class="row-empty"
              :class="{
                'row-half': data.components && data.components.length > 0,
              }"
            >
              从左侧拖拽来添加字段
            </div>
          </draggable>
        </div>
      </el-scrollbar>
    </div>
    <sign v-else-if="data.componentName == 'sign_input'"></sign>
    <my-address
      v-else-if="data.componentName == 'address_input'"
      :area-tree="areaTree"
      :picker="data.picker"
      disabled
    ></my-address>

    <!-- 关联查询 -->
    <div v-else-if="data.componentName == 'form_data'">
      <el-table :data="[]">
        <template v-for="(col, index) in data.columns">
          <el-table-column
            v-if="col.field.componentName != 'reference_data'"
            :align="col.align"
            :key="index"
            :label="col.title"
            :width="
              col.components && col.components.length && col.width
                ? col.width
                : ''
            "
          >
            <template
              v-if="
                col.field.componentName == 'json_form' &&
                col.subColumns &&
                col.subColumns.length
              "
            >
              <el-table-column
                v-for="(subCol, sIndex) in col.subColumns"
                :key="sIndex"
                :align="subCol.align"
                :label="subCol.title"
                :width="subCol.width ? subCol.width : ''"
              >
              </el-table-column>
            </template>
          </el-table-column>
          <template v-else>
            <el-table-column
              v-for="sub in col.subColumns"
              :align="sub.align"
              :key="sub.field.component.name"
              :label="sub.title"
            >
              <template
                v-if="
                  sub.field.componentName == 'json_form' &&
                  sub.field.component.components &&
                  sub.field.component.components.length
                "
              >
                <template
                  v-for="(subCol, sIndex) in sub.field.component.components"
                >
                  <el-table-column
                    v-if="subCol.componentName != 'reference_data'"
                    :key="subCol.key + '_' + sIndex"
                    :align="subCol.align"
                    :label="subCol.title"
                    :width="subCol.width ? subCol.width : ''"
                  >
                  </el-table-column>
                  <template v-else>
                    <el-table-column
                      v-for="(child, childIndex) in subCol.columns"
                      :key="child.field.name + '_' + childIndex"
                      :align="child.align"
                      :label="child.title"
                    >
                    </el-table-column>
                  </template>
                </template>
              </template>
            </el-table-column>
          </template>
        </template>
      </el-table>
    </div>

    <div v-else-if="data.componentName == 'sn_input'">
      <el-input placeholder="自动生成无需填写" disabled readonly></el-input>
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="data.componentName == 'user_select'"
      style="height: 40px"
    >
      选择成员
    </div>
    <div
      v-else-if="data.componentName == 'user_list_select'"
      class="outer-selected-user__empty-wrap"
      style="height: 40px"
    >
      选择成员
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="data.componentName == 'department_select'"
      style="height: 40px"
    >
      选择部门
    </div>
    <div
      v-else-if="data.componentName == 'department_list_select'"
      class="outer-selected-user__empty-wrap"
      style="height: 40px"
    >
      选择部门
    </div>
    <div
      v-else-if="data.componentName == 'position_input'"
      class="fui_location"
    >
      <i class="el-icon-location-information"></i>
      <span>获取定位</span>
    </div>
    <div v-else-if="data.componentName == 'include_form'" class="include_form">
      嵌入表单区域
    </div>
    <el-cascader
      :options="[]"
      style="width: 100%"
      v-else-if="data.componentName == 'parent_data'"
      disabled
    ></el-cascader>
  </el-form-item>
</template>
<script>
import SubComponent from "./component/SubComponent";
import Draggable from "vuedraggable";
import MyAddress from "@/components/address";
import sign from "./component/sign";

export default {
  name: "componentItem",
  components: { SubComponent, Draggable, MyAddress, sign },
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
    currentKey: String,
    put: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  computed: {
    label() {
      if (this.data.title) {
        return this.data.title;
      }
      return this.data.label;
    },
    colGroup2() {
      return {
        name: "column",
        pull: false,
        put: this.put,
      };
    },
  },
  data() {
    return {
      selectValue: "",
      checkboxValue: [],
      actionVisible: false,
    };
  },

  methods: {
    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();

      if (!this.isDrag) {
        this.actionVisible = true;
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      }
    },

    clickItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("changeCurrNode", this.data);
    },
    checkComponent(childIndex, event) {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.$nextTick(() => {
        this.$emit("changeCurrNode", this.data.components[childIndex], event);
      });
    },
    removeSubcomponent(index) {
      this.$confirm("确定删除该子表单字段及对应表单数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.data.components.splice(index, 1);
          this.$emit("removeItem", -1);
        })
        .catch(() => {});
    },
    copySubcomponent(index) {
      let obj = this._.cloneDeep(this.data.components[index]);
      delete obj.name;
      delete obj.key;
      let uuid = this.getUuid();
      obj.key = uuid;
      if (obj.form) {
        obj.name = uuid;
      }
      this.data.components.splice(index + 1, 0, obj);
      this.checkComponent(index + 1);
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
    onAdd(e) {
      this.$nextTick(() => {
        let index = e.newIndex;

        if (!this.data.components[index]) {
          index = this.data.components.findIndex((item) => !item.key);
        }

        if (index >= 0 && !this.data.components[index].key) {
          let uuid = this.getUuid();
          this.$set(this.data.components[index], "key", uuid);
          if (this.data.components[index].form) {
            this.$set(this.data.components[index], "name", uuid);
          }
        }
        this.checkComponent(index);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-checkbox,
.custom-radio {
  text-align: start;
  ::v-deep {
    .el-checkbox,
    .el-radio {
      display: block;
      height: 30px;
      line-height: 30px;
    }
  }
}

.action-item {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  border-radius: 4px;
}
.action-item__hidden {
  position: absolute;
  right: 50px;
  top: 0;
  font-size: 16px;
  z-index: 2;
  height: 20px;
  display: flex;
  cursor: default;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  border-radius: 4px;
}

.json-form {
  background-color: #ffffff;
  border: solid 1px #f4f5f7;
  display: flex;

  .grid {
    border-right: solid 1px #f4f5f7;

    &.is-checked {
      // border: springgreen dashed 1px;

      background-color: rgba($color: #409eff, $alpha: 0.08);
    }
  }

  ::v-deep {
    .el-scrollbar__view {
      height: 100%;
    }
  }

  .grid-flex {
    width: max-content;
    display: flex;
    height: 100%;
    min-width: 100%;

    &::after {
      content: " ";
      display: block;
      clear: both;
    }

    .left {
      float: left;
    }
  }

  .grid-drag {
    display: flex;
    min-width: 100px;
  }

  .th {
    width: 50px;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 5px;
    box-sizing: border-box;
    border-bottom: solid 1px #f4f5f7;
  }

  .td {
    width: 50px;
    min-height: 60px;
    padding: 5px;
    box-sizing: border-box;
  }

  .row-empty {
    font-size: 14px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    width: 160px;
    min-width: 160px;
    height: 100%;
    padding-left: 10px;

    &.row-half {
      width: 80px;
      min-width: 80px;
    }
  }
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
}
.fui_location {
  width: 330px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #e4e7ed;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  transition: background;
  transition-duration: 0.3s;
  background: #fafafa;
  max-width: 100%;
  color: #333;
}
.include_form {
  background: #fafafa;
  height: 32px;
  line-height: 30px;
  padding: 0 10px;
}
</style>
