import { getAreaTree } from "@/api/contact"

export default {
    namespaced: true,
    state: {
        areaTree: [],
        areaProps: {
            label: "name",
            value: "id",
            leaf: "hasChild",
        }
    },
    actions: {
        getAreaTreee({ commit }) {
            getAreaTree().then(res => {
                commit("SET_AREA_TREE", res.data.tree)
            })
        }
    },
    mutations: {
        SET_AREA_TREE: (state, tree) => {
            state.areaTree = tree;
          
        }
    }
}