var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.inJsonForm
        ? [
            _c(
              "div",
              {
                staticClass: "json-row-cell",
                style: _vm.getLeftWidth(),
                on: { click: _vm.clickReference },
              },
              [
                _c("el-button", {
                  staticStyle: { border: "0" },
                  attrs: { icon: "el-icon-coin", size: "small" },
                }),
              ],
              1
            ),
            _vm._l(_vm.item.columns, function (child) {
              return _c(
                "div",
                {
                  key: child.field.name,
                  staticClass: "json-row-cell",
                  style: `width:${
                    _vm.columnsWidth[_vm.item.key + "." + child.field.name]
                  }px;`,
                },
                [
                  child.field.componentName != "json_form"
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "暂无内容",
                          disabled: "",
                          size: "small",
                          value: _vm.getValue(_vm.columnData, child.field),
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ]
        : [
            _c(
              "el-form-item",
              {
                class: { "un-label": !_vm.isShowLabel(_vm.item) },
                style: `width:${100 / _vm.cols}%;`,
                attrs: {
                  label: _vm.isShowLabel(_vm.item) ? _vm.item.title : "",
                  required: !!_vm.item.required,
                  rules: _vm.rules,
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    value: _vm.title,
                    placeholder: "",
                    "suffix-icon": "el-icon-search",
                    clearable: "",
                  },
                  on: {
                    focus: function ($event) {
                      _vm.dialogVisible = true
                    },
                    clear: _vm.clear,
                  },
                }),
              ],
              1
            ),
            _vm._l(_vm.item.columns, function (child) {
              return _c(
                "el-form-item",
                {
                  key: child.field.name,
                  class: { "un-label": !_vm.item.form },
                  style: `width:${100 / _vm.cols}%;`,
                  attrs: { label: child.title },
                },
                [
                  child.field.componentName != "json_form"
                    ? _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "暂无内容",
                          disabled: "",
                          size: "small",
                        },
                      })
                    : _c(
                        "el-table",
                        { staticStyle: { width: "100%" }, attrs: { data: [] } },
                        _vm._l(child.subColumns, function (col) {
                          return _c("el-table-column", {
                            key: col.field.name,
                            attrs: { "min-width": "120px", label: col.title },
                          })
                        }),
                        1
                      ),
                ],
                1
              )
            }),
          ],
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.item.title,
            visible: _vm.dialogVisible,
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.open,
            closed: _vm.closed,
          },
        },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 10px" } },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { inline: true, size: "small", model: _vm.searchForm },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: { size: "small", clearable: "" },
                        model: {
                          value: _vm.searchForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "title", $$v)
                          },
                          expression: "searchForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.search },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        "span-method": _vm.spanMethod,
                        border: "",
                      },
                      on: { "row-click": _vm.rowClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "_index", label: "", width: "50" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("el-radio", {
                                  staticClass: "el-hidden",
                                  attrs: { label: row._id },
                                  model: {
                                    value: _vm.key,
                                    callback: function ($$v) {
                                      _vm.key = $$v
                                    },
                                    expression: "key",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._l(_vm.item.tableColumns, function (col) {
                        return _c(
                          "el-table-column",
                          {
                            key: col.field.name,
                            attrs: {
                              prop: col.field.name,
                              label: col.title,
                              "min-width":
                                col.subColumns && col.subColumns.length
                                  ? ""
                                  : "120px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function ({ row }) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.dealWithChartValue(
                                            row,
                                            col.field.component
                                          )
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            col.subColumns && col.subColumns.length
                              ? _vm._l(col.subColumns, function (child) {
                                  return _c("el-table-column", {
                                    key: child.field.name,
                                    attrs: {
                                      label: child.title,
                                      prop: child.field.name,
                                      "min-width": "120px",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ row }) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.dealWithChartValue(
                                                    row,
                                                    child.field.component
                                                  )
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                })
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                  _c("pagination", {
                    attrs: {
                      total: _vm.page.totalRow,
                      page: _vm.page.pageNumber,
                      limit: _vm.page.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.page, "pageNumber", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.page, "pageSize", $event)
                      },
                      pagination: _vm.loadingList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }