import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
import router from '@/router/index'
import {BASE_URL} from '@/settings'


// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API + BASE_URL, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 500000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    let headers = {
      "client-type": 40,
      "app-type": 10
    }
    if (store.getters.token) {
      headers.token = getToken()
    }
    config.headers = Object.assign(config.headers, headers)
    return config

  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 0, it is judged as an error.
    if (res.code !== 0) {

      //如果接口返回是2999，拒绝请求，返回未登录状态
      if (res.code === 2999) {
        // to re-login
        // MessageBox.confirm('登录已过期，是否回到登录页重新登录？', '确认登出', {
        //     confirmButtonText: '重新登录',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        // }).then(() => {
        //     store.dispatch('user/resetToken').then(() => {
        //         location.reload()
        //     })
        // })
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
        return

      }

      if (res.code === 20000) {
        store.dispatch('user/resetToken').then(() => {
          router.replace("/login")
        })
        return
      }
      if (res.code === 2996) {
        MessageBox.confirm('您的账号在另一个地点登录了智管工，如非本人操作，您的密码已泄露。请尽快进入智管工个人中心修改密码。', {
          confirmButtonText: '重新登录',
          showClose: false,
          showCancelButton: false,
          callback: (action) => {
            if (action == 'confirm') {

              store.dispatch('user/resetToken').then(() => {
                // window.location.href = '/#/login'
                location.reload()
              })
            }
          }
        })
        return
      }
      Message({
        message: res.message || res.msg || '服务错误！',
        type: 'error',
        duration: 5 * 1000
      })
      return Promise.reject(res)
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message || error.msg,
      type: 'error',
      duration: 1 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
