import request from '@/utils/request'

import qs from "qs"


// 获取登录用户所管理的单位列表接口
export function listMyCompany(data) {
    return request({
        url:  '/operatorCompany/listMyCompany',
        method: 'POST',
        data
    })
}

/**
 * 分页列表
 */
export function departmentList(data) {
    return request({
        url:  '/company/department/list',
        method: 'POST',
        data
    })
}
