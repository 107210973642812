import request from '@/utils/request'
import qs from "qs"

//文件上传
export function attachmentUpload(data) {
  return request({
    url:  '/attachment/upload',
    method: 'post',
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data
  })
}

//拉取签名
export function signPull(data) {
  return request({
    url:  '/sign/pull',
    method: 'post',
    data:qs.stringify(data)
  })
}

/**
 * 获取附件详情
 * @param params
 * @returns {AxiosPromise}
 */
export function attachmentDetail(data) {
  return request({
    url:  '/attachment/detail',
    method: 'POST',
    data: qs.stringify(data)
  })
}


/**
 * 保存附件信息到附件
 *postParamsMap.put("attachment.originalName", "我的工程文件.doc"); // 必填
 postParamsMap.put("attachment.path", "20201212/md5值.doc"); // 必填 oss key，格式为: yyyyMMdd(当天)/md5值.扩展名
 postParamsMap.put("attachment.size", 1024); // 必填 单位 kb
 postParamsMap.put("attachment.md5", "md5值"); // 必填
 * @param data
 * @returns {AxiosPromise}
 */
 export function attachmentSave(data) {
  return request({
      url:  '/attachment/save',
      method: 'post',
      data: qs.stringify(data)
  })
}

/**
* 校验文件的md5值是否已经上传过，
/attachment/checkExist?md5=xxxx
* @param data
* @returns {attachmentId}
*/
export function attachmentCheckExits(data) {
  return request({
      url:  '/attachment/checkExist',
      method: 'post',
      data: qs.stringify(data)
  })
}



/**
 * 附件重命名
 */
 export function renameAttachment(data) {
  return request({
      url:  '/attachment/rename',
      method: 'post',
      data
  })
}

/**
* 附件预览
* 暂时只支持文档的预览
*/
export function previewAttachment(data) {
  return request({
      url:  '/attachment/preview',
      method: 'post',
      data
  })
}


export function attachmentDownload(data) {
  return request({
      url:  '/attachment/download',
      method: 'post',
      data: qs.stringify(data)
  })
}
