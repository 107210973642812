var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.formComponent && _vm.formComponent.components
    ? _c(
        "el-form",
        {
          ref: "form",
          staticClass: "cust-form",
          style: !_vm.isInclude ? "margin: 10px 20px" : "",
          attrs: {
            model: _vm.form,
            "label-position": _vm.formComponent.labelPosition,
            "label-width": `${_vm.formComponent.labelWidth}px`,
          },
        },
        [
          _vm._l(_vm.formComponent.components, function (item, index) {
            return [
              _c("form-tpl-item", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !item.hidden,
                    expression: "!item.hidden",
                  },
                ],
                key: index,
                attrs: {
                  isShowComponent: _vm.isShowComponent,
                  cols: _vm.formComponent.cols,
                  form: _vm.form,
                  item: item,
                  "source-list": _vm.sourceList,
                  "area-tree": _vm.areaTree,
                  formType: "add",
                },
                on: {
                  changeFormData: _vm.changeFormData,
                  getMetaOptions: _vm.getMetaOptions,
                },
              }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }