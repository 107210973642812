var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign" }, [
      _c("i", { staticClass: "el-icon-edit sign-edit-icon" }),
      _c("div", { staticClass: "sign-text" }, [_vm._v("手写签名")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }