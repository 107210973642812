var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "el-form-item",
      staticStyle: { margin: "0", padding: "0", width: "100%" },
    },
    [
      !_vm.item.hiddenTitle
        ? _c("label", {
            staticClass: "el-form-item__label",
            staticStyle: { padding: "0 5px" },
            domProps: { textContent: _vm._s(_vm.item.title) },
          })
        : _vm._e(),
      _vm.item.displayMode == "inline"
        ? _c("form-edit", {
            attrs: {
              isInclude: true,
              formComponent: _vm.formComponent,
              areaTree: _vm.areaTree,
            },
          })
        : [
            _c(
              "div",
              { staticClass: "el-input__inner" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { padding: "0" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.visible = true
                      },
                    },
                  },
                  [_vm._v("查看详情")]
                ),
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: "表单预览",
                  visible: _vm.visible,
                  width: "1000px",
                  "append-to-body": "",
                  "destroy-on-close": "",
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.visible = $event
                  },
                },
              },
              [
                _c("form-edit", {
                  ref: "form",
                  attrs: {
                    formComponent: _vm.formComponent,
                    areaTree: _vm.areaTree,
                  },
                }),
                _c("div", { staticStyle: { height: "20px" } }),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }