import request from '@/utils/request'
import store from "@/store";
import qs from "qs"


/**
 * 表单控制
 */

/**
 * 分页列表
 */
export function sysFormListPage(data) {
    return request({
        url:  '/sys/form/paging',
        method: 'POST',
        data
    })
}

/**
 解析模板，可以获取模板中的变量列表
 */
export function sysFormParseTemplate(data) {
    return request({
        url:  '/sys/form/parseTemplate',
        method: 'POST',
        data
    })
}

/**
 表单保存（新增或修改）
 */
export function sysFormSave(data) {
    return request({
        url:  '/sys/form/save',
        method: 'POST',
        data:qs.stringify(data)
    })
}

/**
 表单删除
 */
export function sysFormDelete(data) {
    return request({
        url:  '/sys/form/delete',
        method: 'POST',
        data
    })
}

/**
 根据id获取表单
 */
export function formGetById(data) {
    return request({
        url:  "/sys/form/detail",
        method: 'POST',
        data
    })
}
/**
 获取表单列表接口
 */
export function sysFormList(data) {
    return request({
        url: "/sys/form/list",
        method: 'POST',
        data
    })
}


/**
 获取表单项列表接口
 */
export function sysFormItemList(data) {
    return request({
        url: "/sys/formItem/list",
        method: 'POST',
        data
    })
}




/**
 * 根据表单实例id获取表单实例
 */
 export function getFormInstance(data) {
    return request({
        url:  '/form/instance',
        method: 'POST',
        data: qs.stringify(data)
    })
}

// 给表单指定单位接口
export function assignCompany(data) {
    return request({
        url: "/dir/assignCompany",
        method: 'POST',
        data
    })
}

/**
 * 获取表单元数据字段、默认返回系统字段
 * @param {*} params {tableName,returnWithSystemField:true}
 * @returns
 */
export function fetchMetaFieldList(params, sysArray = []) {
    return new Promise((resolve, reject) => {
        let returnWithSystemField = params.returnWithSystemField !== false;
        request({
            url: "/ui/meta/field/list",
            method: "post",
            data: {
                ...params,
                returnWithSystemField,
            },
        }).then((res) => {
            if (sysArray.length && returnWithSystemField) {
                let sysFields = store.getters.sysFields;
                let sysNames = sysFields.map((item) => item.name);
                let list = res.data.list.filter(
                    (item) =>
                        !sysNames.includes(item.name) ||
                        (sysNames.includes(item.name) && sysArray.includes(item.name))
                );
                res.data.list = list;
            }
            res.data.list.forEach((item) => {
                if (item.component && item.component.componentName == "form_data") {
                    item.component.name = item.component.key;
                    item.name = item.component.key;
                }
            });
            resolve(res);
        });
    });
}

/**
 * 获取系统字段
 * @returns
 */
export function fetchSysFields() {
    return request({
        url: "/ui/meta/field/listDefault",
        method: "post",
    });
}

