import request from "@/utils/request";

import qs from "qs";

/**
 * 公司
 */

/**
 * 分页列表
 */
export function companyList(data) {
  return request({
    url: "/company/list",
    method: "POST",
    data: qs.stringify(data),
  });
}

/**
 * 单位注册
 */
export function companyRegister(data) {
  return request({
    url: "/company/register",
    method: "POST",
    data: qs.stringify(data),
  });
}

/**
 * 单位修改
 */
export function companyModify(data) {
  return request({
    url: "/company/modify",
    method: "POST",
    data: qs.stringify(data),
  });
}

/**
 * 获取单位详情
 */
export function companyDetail(data) {
  return request({
    url: "/company/detail",
    method: "POST",
    data: qs.stringify(data),
  });
}

/**
 * /company/project/detail 单位项目详情
 */
export function companyProjectDetail(data) {
  return request({
    url: "/company/project/detail",
    method: "POST",
    data: qs.stringify(data),
  });
}

/**
 * 单位项目分页列表
 */
export function companyProjectListPage(data) {
  return request({
    url: "/company/project/listPage",
    method: "POST",
    data: qs.stringify(data),
  });
}

//  单位项目保存
export function companyProjectSave(data) {
  return request({
    url: "/company/project/save",
    method: "POST",
    data: qs.stringify(data),
  });
}

// /company/project/section/detail 单位项目分段详情
export function companyProjectSectionDetail(data) {
  return request({
    url: "/company/project/section/detail",
    method: "POST",
    data: qs.stringify(data),
  });
}

//  单位项目分段保存
export function companyProjectSectionSave(data) {
  return request({
    url: "/company/project/section/save",
    method: "POST",
    data: qs.stringify(data),
  });
}
//  单位模版设置模块获取单位列表
export function companyPaging(data) {
  return request({
    url: "/company/paging",
    method: "POST",
    data,
  });
}

/**
 * 添加选择的模板
 * @param data
 * /res/addSelection?dirId=目录ID(必填)&res=资源类型：20看板 40视图 50整编(必填)&resId=资源ID(上面截图的resId，必填)&resName=资源名称(上面截图的名称)(比填)
 * @returns {*}
 */
export function resourceAddSelection(data) {
  return request({
    url: "/res/addSelection",
    method: "POST",
    data,
  });
}

/**
 * 重命名已选的模板(旧接口)
 * @param data
 * @returns {*}
 */
export function resourceRename(data) {
  return request({
    url: "/res/rename",
    method: "POST",
    data,
  });
}

/**
 * 删除模版(旧接口)
 * @param data
 * @returns {*}
 */
export function resourceDelete(data) {
  return request({
    url: "/res/delete",
    method: "POST",
    data,
  });
}
/**
 * 添加选择的目录接口：
 * @param data
 * @returns {*}
 */
export function resourceAddSelectionDir(data) {
  return request({
    url: "/res/addSelectionDir",
    method: "POST",
    data,
  });
}

// 获取单位运营人员列表
export function operatorCompany(data) {
  return request({
    url: "/operatorCompany/paging",
    method: "POST",
    data,
  });
}

// 获取平台注册用户列表
export function platformUserPaging(data) {
  return request({
    url: "/operatorCompany/platformUserpaging",
    method: "POST",
    data,
  });
}

// 设置单位运营人员
export function createOperate(data) {
  return request({
    url: "/operatorCompany/create",
    method: "POST",
    data,
  });
}

// 设置单位运营人员角色
export function setOperateRole(data) {
  return request({
    url: "/operatorCompany/setRole",
    method: "POST",
    data,
  });
}

// 删除单位运营人员
export function deleteOperate(data) {
  return request({
    url: "/operatorCompany/delete",
    method: "POST",
    data,
  });
}
//员工列表
export function companyEmployeeList(data) {
  return request({
    url: "/company/employee/list",
    method: "post",
    data: qs.stringify(data),
  });
}

// 设置取消单位运营管理员
export function switchAdmin(data) {
  return request({
    url: "/operatorCompany/switchAdmin",
    method: "POST",
    data,
  });
}

// 复制单位
export function duplicateCompanyProject(data) {
  return request({
    url: "/res/duplicateCompanyProject",
    method: "POST",
    data,
  });
}

// 复制单位前的检查
export function checkError(data) {
  return request({
    url: "/res/checkCompanyProjectErrorCollection",
    method: "POST",
    data,
  });
}

// 获取开关设置详情信息接口：/fs/storageConfig/detail?companyId=单位ID(必填)
export function devConfigDetail(data) {
  return request({
    url: "/fs/storageConfig/detail",
    method: "POST",
    data,
  });
}
// 保存 开关设置接口/srv/company/devConfig/save?model={上面的对象信息}
export function devConfigSave(data) {
  return request({
    url: "/fs/storageConfig/save",
    method: "POST",
    data,
  });
}

/**
 * 短信平台类型
 * @returns 
 */
export function getSmsPlatformType() {
  return request({
    url: "/sms/platform/type",
    method: "POST",
  });
}


/**
 * 短信平台列表
 * @returns 
 */
export function getSmsPlatformList() {
  return request({
    url: "/sms/platform/list",
    method: "POST",
  });
}

/**
 * 短信平台配置保存
 * @param {*} platform
 * @param {String} accessKeyId 【key】
 * @param {String} accessKeySecret 【密钥】
 * @param {String} companyId
 * @param {String} regionId 【区域】
 * @param {String} sdkAppId 【应用id腾讯必填】
 * @param {String} signName 【短信签名腾讯必填】
 * @param {String} smsType 【短信平台类型】
 * @returns 
 */
export function smsPlatformSave(data) {
  return request({
    url: "/sms/platform/save",
    method: "POST",
    data,
  });
}

/**
 * 短信平台详情
 * @param {*} data
 * @param {String} id
 */
export function smsPlatformDetail(data) {
  return request({
    url: "/sms/platform/detail",
    method: "POST",
    data,
  });
}

/**
 * 短信平台开启禁用
 * @param {*} data
 * @param {String} id
 */
export function smsPlatformSwitchEnabled(data) {
  return request({
    url: "/sms/platform/switchEnabled",
    method: "POST",
    data,
  });
}

/**
 * 短信平台删除已启用的平台不可删除
 * @param {*} data
 * @param {Array} ids
 */
export function smsPlatformDelete(data) {
  return request({
    url: "/sms/platform/delete",
    method: "POST",
    data,
  });
}

/**
 * 短信通知编码
 * @returns 
 */
export function getSmsTplSysCode() {
  return request({
    url: "/sms/template/sysCode",
    method: "POST",
  });
}

/**
 * 短信通知类型
 * @returns 
 */
export function getSmsTplNoticeType() {
  return request({
    url: "/sms/template/noticeType",
    method: "POST",
  });
}

/**
 * 短信模板保存
 * @param {*} smsTemplate 
 * @param {string} id 【编辑时必须】
 * @param {string} sysCode 【短信通知编码-系统内置编码】
 * @param {string} templateContent
 * @param {string} companyId
 * @param {string} templateName 【模板名称】
 * @param {string} templateType 【短信通知类型】
 * @param {string} smsType 【短信平台类型】
 * @param {object} fieldConfig 【占位符与字段对应信息】
 * @returns 
 */
export function smsTplSave(data) {
  return request({
    url: "/sms/template/save",
    method: "POST",
    data,
  });
}

/**
 * 短信模板列表
 * @param {*} data 
 * @param {string} templateStatus 
 * @param {String} smsType 【短信平台类型】
 * @returns 
 */
export function getSmsTplList(data) {
  return request({
    url: "/sms/template/list",
    method: "POST",
    data,
  });
}

/**
 * 短信模板详情
 * @param {*} data 
 * @param {string} id
 * @returns 
 */
export function getSmsTplDetail(data) {
  return request({
    url: "/sms/template/detail",
    method: "POST",
    data,
  });
}

/**
 * 短信模板删除
 * @param {*} data 
 * @param {array} ids 
 * @returns 
 */
export function smsTplDelete(data) {
  return request({
    url: "/sms/template/delete",
    method: "POST",
    data,
  });
}

/**
 * 模板审批流程占位符对应字段
 * @param {*} data 
 * @param {string} sysCode 【短信模版类型】 
 * @returns 
 */
export function getSmsTplFlowField(data) {
  return request({
    url: "/sms/template/flowField",
    method: "POST",
    data,
  });
}
