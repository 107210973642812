<template>
  <div @mouseenter="mouseenter" @mouseleave="mouseleave" class="ref-container">
    <div class="action-item" v-show="actionVisible">
      <i
        @click="copyItem"
        @mousedown.stop=""
        class="el-icon-copy-document"
        title="复制"
        style="margin-right: 5px; cursor: pointer"
      ></i>
      <i
        @click="removeItem"
        @mousedown.stop=""
        title="删除"
        class="el-icon-delete"
        style="cursor: pointer"
      ></i>
    </div>
    <el-form-item
      :label="label"
      :required="!!data.required"
      :class="{ 'un-label': !data.form }"
      style="width: 100%"
    >
      <el-button size="small">选择数据</el-button>
    </el-form-item>
    <el-form-item
      v-for="child in data.columns"
      :key="child.field.name"
      :class="{ 'un-label': !data.form }"
      style="width: 100%"
    >
      <span slot="label"
        ><i class="el-icon-connection"></i><span v-text="child.title"></span
      ></span>
      <el-input
        v-if="child.field.componentName != 'json_form'"
        style="width: 100%"
        placeholder="暂无内容"
        disabled
        size="small"
      ></el-input>
      <el-table v-else :data="[]" style="width: 100%">
        <el-table-column
          min-width="120px"
          v-for="col in child.subColumns"
          :key="col.field.name"
          :label="col.title"
        >
        </el-table-column>
      </el-table>
    </el-form-item>
  </div>
</template>
<script>
export default {
  props: {
    index: Number,
    data: Object,
    isDrag: Boolean,
    areaTree: Array,
  },
  computed: {
    label() {
      if (this.data.title) {
        return this.data.title;
      }
      return this.data.label;
    },
  },
  data() {
    return {
      selectValue: "",
      checkboxValue: [],
      currIndex: -1,
      actionVisible: false,
    };
  },
  methods: {
    mouseenter(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = true;
      }
    },
    mouseleave(event) {
      event.preventDefault();
      event.stopPropagation();
      if (!this.isDrag) {
        this.actionVisible = false;
      }
    },
    copyItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("copyItem", this.index);
    },
    removeItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("removeItem", this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.ref-container {
  position: relative;
}

.action-item {
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 16px;
  z-index: 10;
  height: 20px;
  display: flex;
  cursor: default;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  border-radius: 4px;
}
</style>