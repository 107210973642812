var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.componentName == "reference_data"
      ? _c(
          "div",
          {
            staticClass: "sub-reference",
            on: {
              mouseenter: _vm.mouseenter,
              mouseleave: _vm.mouseleave,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.actionVisible,
                    expression: "actionVisible",
                  },
                ],
                staticClass: "sub-remove",
              },
              [
                _c("i", {
                  staticClass: "el-icon-copy-document",
                  staticStyle: { "margin-right": "5px" },
                  attrs: { title: "复制" },
                  on: {
                    click: _vm.copyItem,
                    mousedown: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-delete",
                  attrs: { title: "删除" },
                  on: {
                    click: _vm.removeItem,
                    mousedown: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                }),
              ]
            ),
            _c("div", [
              _c("div", { staticClass: "sub-th" }, [
                _vm.data.required
                  ? _c("div", { staticStyle: { color: "#f56c6c" } }, [
                      _vm._v("*"),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "sub-text" }, [
                  _c("div", {
                    style:
                      _vm.data.columns && _vm.data.columns.length
                        ? "width: 50px"
                        : "width:150px;",
                    domProps: {
                      textContent: _vm._s(
                        _vm.data.columns && _vm.data.columns.length
                          ? ""
                          : _vm.label
                      ),
                    },
                  }),
                ]),
              ]),
              _vm._m(0),
            ]),
            _vm.data.columns
              ? _vm._l(_vm.data.columns, function (child) {
                  return _c(
                    "div",
                    {
                      key: child.field.name,
                      staticStyle: { "min-width": "160px" },
                    },
                    [
                      _c("div", { staticClass: "sub-th" }, [
                        _c("div", {
                          staticClass: "sub-text",
                          domProps: { textContent: _vm._s(child.title) },
                        }),
                      ]),
                      _c("sub-input", {
                        staticClass: "sub-td",
                        attrs: { data: child.field },
                      }),
                    ],
                    1
                  )
                })
              : _vm._e(),
          ],
          2
        )
      : _c(
          "div",
          {
            staticStyle: { "min-width": "160px" },
            on: {
              mouseenter: _vm.mouseenter,
              mouseleave: _vm.mouseleave,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("div", { staticClass: "sub-th" }, [
              _vm.data.required
                ? _c("div", { staticStyle: { color: "#f56c6c" } }, [
                    _vm._v("*"),
                  ])
                : _vm._e(),
              _c("div", {
                staticClass: "sub-text",
                domProps: { textContent: _vm._s(_vm.label) },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.actionVisible,
                      expression: "actionVisible",
                    },
                  ],
                  staticClass: "sub-remove",
                },
                [
                  _c("i", {
                    staticClass: "el-icon-copy-document",
                    staticStyle: { "margin-right": "5px" },
                    attrs: { title: "复制" },
                    on: {
                      click: _vm.copyItem,
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  }),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    attrs: { title: "删除" },
                    on: {
                      click: _vm.removeItem,
                      mousedown: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  }),
                ]
              ),
            ]),
            _c("sub-input", {
              staticClass: "sub-td",
              attrs: { data: _vm.data },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sub-td" }, [
      _c("i", { staticClass: "el-icon-coin" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }