const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  user: state => state.user,
  area: state => state.area,
  bulletinTagList: state => state.bulletin.bulletinTagList,
  sysFields: state => state.sysFields.sysFields,
  sysFieldNames: state => state.sysFields.sysFieldNames
}
export default getters
