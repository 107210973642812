export function getFileExtension(extension) {
    let suffix = ""
    if (extension) {
        suffix = extension.toLowerCase();
    }
    let enums = {
        jpg: "jpg",
        jpeg: "jpeg",
        png: "png",
        bmp: "bmp",
        heic: "heic",
        ico: "ico",
        pdf: "pdf",
        mp4: "mp4",
        mp3: "mp3",
        m4p: "m4p",
        flv: "flv",
        m3u: "m3u",
        wav: "wav",
        wma: "wma",
        txt: "txt"
    }
    return enums[suffix];
}

export function getRecordCNName(recordType) {
    let enums = {
        division: "项目划分",
        company_project: "项目库",
        company_project_section: "项目目录",
        plan: "资料整编",
        document: "我的文件",
        contract_income: "合同收款",
        contract_payment: "项目支出",
        contract_company: "单位合同",
        material: "清单管理",
        bulletin: "看板详情",
        project_excel: '项目表格',
        project_division: '项目划分'
        // section:"标段",
        // project:"项目库项目"
    }
    return enums[recordType]
}

export function getRecordRouteName(recordType) {
    let enums = {
        division: "项目划分详情",
        company_project_section: "子项目详情",
        company_project: "项目详情",
        contract_income: "收款详情",
        contract_payment: "支出详情",
        contract_company: "合同详情",
        plan:"目录详情",
        material: "清单详情",
        bulletin: "看板详情",
        excel_template: "表格模板详情",
        resource_apply: "数字档案馆",
        project_excel: "项目表格详情",
        project_division: "划分"
        // section:"标段",
    }
    return enums[recordType]
}
