<template>
  <div class="my-divider" :class="`is-${titleAlign}`">
    <span class="divider-text" v-if="lineType == 'horizontal'">
      <slot></slot>
    </span>
    <div class="line" :style="style" :class="`line-${lineType}`"></div>
  </div>
</template>
<script>
export default {
  computed: {
    style() {
      let sh = this.dashed ? "dashed" : "solid";
      let x = this.lineType == "horizontal" ? "top" : "left";
      let str = `border-${x}:1px ${this.lineColor} ${sh}`;

      return str;
    },
  },
  props: {
    titleAlign: {
      type: String,
      default() {
        return "left";
      },
    },
    lineColor: {
      type: String,
      default() {
        return "#DCDFE6";
      },
    },
    lineType: {
      type: String,
      default() {
        return "horizontal";
      },
    },
    dashed: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.my-divider {
  position: relative;
  padding: 0px 20px;
  .line {
    position: absolute;
  }
  .line-horizontal {
    left: 0px;
    top: 50%;
    width: 100%;
  }
  .line-vertical {
    height: 1em;
    position: static;
  }
  .divider-text {
    position: relative;
    z-index: 2;
    background-color: #ffffff;
    font-weight: 600;
  }
  &.is-left {
    text-align: left;
  }
  &.is-center {
    text-align: center;
  }
  &.is-right {
    text-align: right;
  }
}
</style>