<template>
  <el-input
    v-if="
      item.componentName == 'input' ||
      item.componentName == 'input_number' ||
      item.componentName == 'mobile_input'
    "
    v-model="form[item.name]"
    :placeholder="item.placeHolder"
    @blur="blurInput($event, -1)"
    :readonly="!!item.readonly"
    ><template
      v-if="item.componentName == 'input_number' && item.format == 'percent'"
      slot="append"
      >%
    </template>
    <i
      v-if="item.componentName == 'mobile_input'"
      slot="prepend"
      class="el-icon-mobile"
    >
    </i>
  </el-input>
  <el-input
    v-else-if="item.componentName == 'text_area'"
    v-model="form[item.name]"
    type="textarea"
    :autosize="{ minRows: 3 }"
    :placeholder="item.placeHolder"
    :readonly="!!item.readonly"
  ></el-input>
  <el-date-picker
    v-else-if="item.componentName == 'date_picker'"
    v-model="value"
    :type="item.picker"
    :placeholder="item.placeHolder"
    :disabled="item.readonly"
  ></el-date-picker>
  <el-select
    style="width: 100%"
    v-else-if="item.componentName == 'select'"
    :placeholder="item.placeHolder"
    v-model="value"
    :disabled="!!item.readonly"
    v-loadmore="loadMore"
    :filterable="true"
    :remote="item.optionSource == 'metaField'"
    :loading="loading"
    :remote-method="searchList"
    :popper-class="
      !!(
        item.optionSource == 'metaField' &&
        item.metaFieldOption.tableName &&
        item.metaFieldOption.tableColumns &&
        item.metaFieldOption.tableColumns.length
      )
        ? 'metaSelect'
        : ''
    "
  >
    <el-option
      v-for="(opt, index) in options"
      :key="index"
      :label="opt.label"
      :value="opt.value"
    >
      <template
        v-if="
          item.optionSource == 'metaField' &&
          item.metaFieldOption.tableName &&
          item.metaFieldOption.tableColumns &&
          item.metaFieldOption.tableColumns.length
        "
      >
        <div class="opt-item" :style="`width:${width ? width : ''}px;`">
          <div class="opt-title" v-text="opt.label"></div>
          <div class="sub-title" v-text="getSubTitle(opt)"></div>
        </div>
      </template>
    </el-option>
  </el-select>
  <el-select
    v-else-if="item.componentName == 'select_checkbox'"
    :placeholder="item.placeHolder"
    v-model="value"
    :disabled="!!item.readonly"
    multiple
    filterable
    collapse-tags
  >
    <el-option
      v-for="(opt, index) in options"
      :key="index"
      :label="opt.label"
      :value="opt.value"
    ></el-option>
  </el-select>
  <el-radio-group
    v-model="value"
    v-else-if="item.componentName == 'radio_group'"
    :disabled="!!item.readonly"
  >
    <el-radio v-for="(opt, index) in options" :key="index" :label="opt.value"
      >{{ opt.label }}
    </el-radio>
  </el-radio-group>
  <el-checkbox-group
    v-model="value"
    v-else-if="item.componentName == 'checkbox_group'"
    :disabled="!!item.readonly"
  >
    <el-checkbox v-for="(opt, index) in options" :label="opt.value" :key="index"
      >{{ opt.label }}
    </el-checkbox>
  </el-checkbox-group>
  <my-divider
    v-else-if="item.componentName == 'divider'"
    :line-type="item.lineType"
    :title-align="item.titleAlign"
    :dashed="item.dashed"
    :line-color="item.lineColor"
    >{{ item.title }}
  </my-divider>
  <sign v-else-if="item.componentName == 'sign_input'"></sign>
  <form-data
    ref="form_data"
    v-else-if="item.componentName == 'form_data'"
    :column="item"
    :form="form"
  ></form-data>

  <my-address
    v-else-if="item.componentName == 'address_input'"
    v-model="value"
    :picker="item.picker"
    :area-tree="areaTree"
    :disabled="!!item.readonly"
  ></my-address>
  <div
    v-else-if="
      ['attachment_uploader', 'image_uploader'].includes(item.componentName)
    "
  >
    <form-upload v-model="value" v-if="isInitValue"></form-upload>
    <el-button v-else type="primary" size="mini"
      >{{
        item.componentName == "attachment_uploader" ? "上传附件" : "上传图片"
      }}
    </el-button>
  </div>

  <el-input
    v-else-if="item.componentName == 'sn_input'"
    v-model="value"
    placeholder="自动生成无需填写"
    :readonly="!!item.readonly"
    disabled
  >
  </el-input>

  <div
    class="outer-selected-user__empty-wrap"
    v-else-if="item.componentName == 'department_select'"
    style="height: 40px; width: 100%"
  >
    选择部门
  </div>
  <div
    v-else-if="item.componentName == 'department_list_select'"
    class="outer-selected-user__empty-wrap"
    style="height: 40px; width: 100%"
  >
    选择部门
  </div>

  <div
    class="outer-selected-user__empty-wrap"
    v-else-if="['user_select', 'user_list_select'].includes(item.componentName)"
    style="height: 40px; width: 100%"
  >
    选择用户
  </div>
  <!-- <FormUserSelect
    v-else-if="item.componentName == 'user_list_select'"
    ref="formUserSelect"
    :multiple="true"
    :hide-outer="false"
    @changeUsers="changeUsers"
    dialog-title="选择用户"
    :reset-on-close="true"
    :onlyCompany="false"
  ></FormUserSelect>

  <FormUserSelect
    v-else-if="item.componentName == 'user_select'"
    ref="formUserSelect"
    :multiple="false"
    :hide-outer="false"
    @changeUsers="changeUsers"
    dialog-title="选择用户"
    :reset-on-close="true"
    :onlyCompany="false"
  ></FormUserSelect> -->

  <div v-else-if="item.componentName == 'position_input'" class="fui_location">
    <i class="el-icon-location-information"></i>
    <span>获取定位</span>
  </div>
</template>
<script>
import MyAddress from "@/components/address";
import sign from "./sign";
import test from "@/utils/test";
import FormData from "./FormData";
import { isJsonValue, isArrayValue } from "../utils";
import dataLinkMixin from "@/views/lowCode/form/component/dataLinkMixin";
import { computedFun, isEmpty, _eq } from "@zgg-core-utils/relyUtil";
import FormUserSelect from "@/components/FormUserSelect";
import { fetchFormDataList } from "../api";
import { dealWithChartValue } from "../../view/components/chartUtil";
import FormUpload from "@/views/lowCode/form/component/FormUpload";

export default {
  inject: ["getComponents"],
  components: { MyAddress, sign, FormData, FormUserSelect, FormUpload },
  mixins: [dataLinkMixin],
  name: "FormItem",
  props: {
    form: Object,
    item: Object,
    areaTree: Array,
    parentForm: Object,
    watchForm: Object,
    isInitValue: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isJsonFormItem: {
      type: Boolean,
      default() {
        return false;
      },
    },
    rowIndex: {
      type: Number,
      default() {
        return -1;
      },
    },
    parentName: {
      type: String,
      default() {
        return "";
      },
    },
    sourceList: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    cForm() {
      return JSON.parse(JSON.stringify(this.watchForm));
    },
    options() {
      if (this.item.optionSource == "custom") {
        return this.item.customOptions;
      } else if (this.item.optionSource == "metaField") {
        if (this.item.componentName != "select") {
          if (this.sourceList[this.item.metaFieldOption.tableName]) {
            let list = [];

            let valueFieldName = this.item.metaFieldOption.valueFieldName;
            let labelFieldName = this.item.metaFieldOption.labelFieldName;
            this.sourceList[this.item.metaFieldOption.tableName].forEach(
              (obj) => {
                if (
                  list.findIndex((col) => col.value == obj[valueFieldName]) ==
                  -1
                ) {
                  list.push({
                    value: obj[valueFieldName],
                    label: obj[labelFieldName],
                  });
                }
              }
            );
            return list;
          }
        } else {
          return this.metaFieldPage.list;
        }
      } else if (this.item.optionSource == "dataLinkage") {
        return this.optionsList;
      }

      return [];
    },
    value: {
      get() {
        let value = this.form[this.item.name];
        if (isArrayValue(this.item.componentName)) {
          if (!this._.isArray(value)) {
            return [];
          }
        }
        return value;
      },
      set(val) {
        if (this.valueTimer) {
          // clearTimeout(this.valueTimer);
        }

        let value = val;
        // if (!isEmpty(value) && this.item.componentName == "date_picker") {
        //   value = moment(val).format(this.getMomentFromat(this.item.picker));
        // }

        // this.valueTimer = setTimeout(() => {

        this.$emit("changeFormData", {
          name: this.item.name,
          value: value,
          callback: () => {
            if (this.watchName) {
              this.watchLoading[this.watchName] = false;
            }
          },
        });
        // }, 20);
      },
    },
  },
  data() {
    return {
      width: 0,
      optionsList: [],
      timer: "",
      valueTimer: "",
      watchName: "",
      watchLoading: {},
      componentList: [],
      loading: false,
      metaFieldPage: {
        pageSize: 20,
        pageNumber: 1,
        totalRow: 0,
        totalPage: 0,
        list: [],
      },
    };
  },
  created() {
    if (!this.isJsonFormItem) {
      this.initComponent();
    }
    this.$set(this, "componentList", this._.cloneDeep(this.getComponents()));

    if (
      this.item.componentName == "select" &&
      this.item.optionSource == "metaField" &&
      this.item.metaFieldOption.tableName
    ) {
      this.loadingList();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.width = this.$el.clientWidth;
    });
  },
  methods: {
    changeUsers(users) {
      console.log(users);
    },
    getSubTitle(opt) {
      let str = "";

      this.item.metaFieldOption.tableColumns.forEach((row) => {
        let value = opt[row.field.name];
        if (!isEmpty(value)) {
          if (str != "") {
            str += "|";
          }
          str += dealWithChartValue(opt, row.field.component);
        }
      });
      return str;
    },
    loadMore() {
      if (this.item.optionSource == "metaField") {
        if (this.metaFieldPage.pageNumber < this.metaFieldPage.totalPage) {
          this.metaFieldPage.pageNumber += 1;
          this.loadingList();
        }
      }
    },
    searchList(query) {
      if (this.isInitValue && this.isJsonFormItem) {
        return;
      }
      this.metaFieldPage.pageNumber = 1;
      this.loadingList(query);
    },
    loadingList(query) {
      if (this.isInitValue && this.isJsonFormItem) {
        return;
      }
      let queryObject = {
        pageNumber: this.metaFieldPage.pageNumber,
        pageSize: this.metaFieldPage.pageSize,
      };
      let sortParam = {};
      let item = this.item;
      if (item.metaFieldOption.orderBy == "orderByOptionValueAsc") {
        sortParam[item.metaFieldOption.valueFieldName] = "asc";
      } else if (item.metaFieldOption.orderBy == "orderByOptionValueDesc") {
        sortParam[item.metaFieldOption.valueFieldName] = "desc";
      } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
        sortParam["createdTime"] = "asc";
      } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeDesc") {
        sortParam["createdTime"] = "desc";
      }
      if (Object.keys(sortParam).length) {
        queryObject.sortParam = sortParam;
      }

      let advanceQuery = [];

      if (!isEmpty(query)) {
        let arr = [];
        arr.push({
          oper: "like",
          key: item.metaFieldOption.valueFieldName,
          value: query,
        });
        item.metaFieldOption.tableColumns.forEach((col) => {
          arr.push({
            oper: "like",
            key: col.field.name,
            value: query,
          });
        });
        advanceQuery.push({
          queryChain: {
            or: arr,
          },
        });
      }
      if (advanceQuery.length) {
        queryObject.advanceQuery = advanceQuery;
      }
      fetchFormDataList({
        collection: item.metaFieldOption.tableName,
        queryObject: JSON.stringify(queryObject),
      }).then((res) => {
        let page = res.data.page;
        this.metaFieldPage.pageNumber = page.pageNumber;
        this.metaFieldPage.pageSize = page.pageSize;
        this.metaFieldPage.totalRow = page.totalRow;
        this.metaFieldPage.totalPage = page.totalPage;
        let valueFieldName = item.metaFieldOption.valueFieldName;
        let labelFieldName = item.metaFieldOption.labelFieldName;
        if (page.pageNumber == 1) {
          let list = [];

          page.list.forEach((row) => {
            row.label = row[labelFieldName];
            row.value = row[valueFieldName];
            if (
              list.findIndex(
                (obj) => obj[valueFieldName] == row[valueFieldName]
              ) == -1
            ) {
              list.push(row);
            }
          });
          this.metaFieldPage.list = page.list;
        } else {
          page.list.forEach((row) => {
            row.label = row[labelFieldName];
            row.value = row[valueFieldName];
            if (
              this.metaFieldPage.list.findIndex(
                (obj) => obj[valueFieldName] == row[valueFieldName]
              ) == -1
            ) {
              // list.push(row);
              this.metaFieldPage.list.push(row);
            }
          });
        }
      });
    },
    initComponent() {
      this.buildMetaData();
      this.initValue();
      this.buildRely();
      this.buildDataLink();
    },
    isEmptyName(item) {
      if (item.parentName) {
        return isEmpty(this.form[item.name]);
      } else {
        return isEmpty(this.parentForm[item.name]);
      }
    },
    getFieldValue(obj, form) {
      let value;

      if (this.isJsonFormItem) {
        // 子表单的公式，子表单不能取其他子表单的字段参与计算，所以子表单如果存在就是关联数据
        if (obj.parent && obj.parent.componentName == "reference_data") {
          let referenceData = this.form[obj.parent.name + "ReferenceData"];
          if (referenceData) {
            value = referenceData[obj.name];
          }
        } else {
          return form[obj.name];
        }
      } else {
        if (obj.parent) {
          let item = this.getFieldValue(obj.parent, form);
          if (item) {
            if (obj.parent.componentName == "json_form") {
              // 子表单
              value = item.map((col) => col[obj.name]);
            } else {
              value = item[obj.name];
            }
          }
        } else if (obj.parentName) {
          // 旧数据只有子表单才有parentName 这个属性
          let item = form[obj.parentName];
          if (item) {
            value = item.map((col) => col[obj.name]);
          }
        } else {
          if (obj.componentName == "reference_data") {
            value = form[obj.name + "ReferenceData"];
          } else {
            value = form[obj.name];
          }
        }
      }
      return value;
    },

    buildRely() {
      // 构建公式
      if (!this.item.rely) {
        return;
      }

      let isAuto = true;
      let isEmptyField = true;
      this.item.rely.originalFormula.forEach((rows) => {
        rows
          .filter((item) => item.type == "field")
          .forEach((item) => {
            if (!isEmpty(this.getFieldValue(item, this.watchForm))) {
              isEmptyField = false;
            }
          });
        isAuto = isAuto && rows.findIndex((item) => item.type == "field") == -1;
      });

      this.$watch("cForm", (val, old) => {
        this.item.rely.originalFormula.forEach((rows) => {
          rows
            .filter((item) => item.type == "field")
            .forEach((item) => {
              let name = item.name;
              let valValue, oldValue;
              valValue = this.getFieldValue(item, val);
              oldValue = this.getFieldValue(item, old);

              this.watchName = name;
              if (this.watchLoading[name]) {
                this.watchLoading[name] = false;
                return;
              }
              if (isEmpty(valValue) && isEmpty(oldValue)) {
                return;
              }
              if (_eq(valValue, oldValue)) {
                return;
              }
              this.watchLoading[name] = true;
              let value = computedFun.bind(this)(
                {
                  ...this.item.rely,
                  parentName: this.parentName,
                  componentName: this.item.componentName,
                },
                this.rowIndex
              );
              // this.value = value;
              this.formatValue(value);
            });
        });
      });
      if (isAuto || !isEmptyField) {
        // 自动执行公式
        let value = computedFun.bind(this)(
          {
            ...this.item.rely,
            parentName: this.parentName,
            componentName: this.item.componentName,
          },
          this.rowIndex
        );

        // this.value = value;
        this.formatValue(value);
      }
    },

    initValue() {
      // 初始化值

      let value;
      if (isJsonValue(this.item.componentName)) {
        if (
          typeof this.item.defaultValue === "string" &&
          !isEmpty(this.item.defaultValue)
        ) {
          value = JSON.parse(this.item.defaultValue);
        } else {
          if (isArrayValue(this.item.componentName)) {
            value = [];
          } else {
            value = {};
          }
        }
      } else {
        value = this.item.defaultValue;
      }

      this.$emit("changeFormData", {
        name: this.item.name,
        type: "init",
        value,
      });
    },
    buildMetaData() {
      // 获取下拉，单选、多选 关联其他表单数据
      let arr = ["radio_group", "checkbox_group", "select_checkbox"];
      let item = this.item;
      if (
        !(
          arr.includes(item.componentName) &&
          item.optionSource == "metaField" &&
          item.metaFieldOption &&
          item.metaFieldOption.tableName
        )
      ) {
        return;
      }
      if (this.sourceList.length) {
        return;
      }

      let queryObject = {
        pageNumber: 1,
        pageSize: 999,
      };

      let sortParam = {};
      if (item.metaFieldOption.orderBy == "orderByOptionValueAsc") {
        sortParam[item.metaFieldOption.valueFieldName] = "asc";
      } else if (item.metaFieldOption.orderBy == "orderByOptionValueDesc") {
        sortParam[item.metaFieldOption.valueFieldName] = "desc";
      } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeAsc") {
        sortParam["createdTime"] = "asc";
      } else if (item.metaFieldOption.orderBy == "orderByCreatedTimeDesc") {
        sortParam["createdTime"] = "desc";
      }
      if (Object.keys(sortParam).length) {
        queryObject.sortParam = sortParam;
      }
      this.$emit("getMetaOptions", {
        collection: item.metaFieldOption.tableName,
        queryObject: JSON.stringify(queryObject),
      });
    },

    blurInput(event, $index) {
      let value = event.currentTarget.value;
      this.formatValue(value);
    },
    formatValue(val) {
      let value = "";
      if (!isEmpty(val)) {
        value = val.toString();
      }

      if (
        this.item.componentName == "input_number" &&
        this.item.decimalPrecision &&
        test.number(value)
      ) {
        // value =
        let index = value.indexOf(".");
        let num = this.item.decimalPrecision;

        if (index > 0) {
          value = value.substring(0, index + num + 1);
        } else if (index == 0) {
          value = value.substring(0, index + num + 1);

          if (num == 0) {
            value = 0;
          } else {
            value = "0" + value;
          }
        }
        value = new Number(value).toFixed(num);
        this.value = parseFloat(value);
      } else if (
        this.item.componentName == "input_number" &&
        test.number(value) &&
        value != ""
      ) {
        this.value = parseFloat(value);
      } else {
        this.value = value;
      }
    },
    getMomentFromat(type) {
      if (type == "year") {
        return "YYYY";
      } else if (type == "month") {
        return "YYYY-MM";
      } else if (type == "date") {
        return "YYYY-MM-DD";
      } else {
        return "YYYY-MM-DD HH:mm:ss";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-upload {
  width: 160px;
  height: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  border: solid 1px #e4e7ed;
  border-radius: 5px;

  .el-icon-upload {
    color: #c0c4cc;
    font-size: 24px !important;
  }
}
.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
}
.fui_location {
  width: 330px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #e4e7ed;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  overflow: hidden;
  transition: background;
  transition-duration: 0.3s;
  background: #fafafa;
  max-width: 100%;
  color: #333;
}

.opt-item {
  font-size: 12px;
  line-height: 20px;
  .opt-title {
    color: #333;
    white-space: pre-wrap;
  }
  .sub-title {
    color: #666;
    white-space: pre-wrap;
  }
}
</style>
<style lang="scss">
.metaSelect {
  .el-select-dropdown__item {
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
    line-height: normal;
  }
}
</style>
