import SparkMD5 from 'spark-md5'
/**
 *
 * @param sourceObject
 * @param prefix
 * @returns {*}
 */
export function getFormModel(sourceObject,prefix) {
  let data = {}
  Object.keys(sourceObject).map(key=>{
    data[prefix+"."+key] = sourceObject[key]
  })
  return data
}





/**
 * 用spark-md5计算文件md5
 * @param file
 */
export function hashFile(file,callback) {
    var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
        chunkSize = 2097152, // read in chunks of 2MB
        chunks = Math.ceil(file.size / chunkSize),
        currentChunk = 0,
        spark = new SparkMD5.ArrayBuffer(),
        frOnload = function (e) {
            spark.append(e.target.result); // append array buffer
            currentChunk++;
            if (currentChunk < chunks)
            {
                loadNext();
            }
            else
            {
                callback(spark.end())
            }
        },
        frOnerror = function () {
           console.log("err")
        };

    function loadNext() {
        var fileReader = new FileReader();
        fileReader.onload = frOnload;
        fileReader.onerror = frOnerror;
        var start = currentChunk * chunkSize,
            end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
        fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };
     loadNext();

}
