var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExternal
    ? _c(
        "div",
        _vm._g(
          {
            staticClass: "svg-external-icon svg-icon",
            style: _vm.styleExternalIcon,
          },
          _vm.$listeners
        )
      )
    : _c(
        "svg",
        _vm._g(
          { class: _vm.svgClass, attrs: { "aria-hidden": "true" } },
          _vm.$listeners
        ),
        [_c("use", { attrs: { href: _vm.iconName } })]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }