<template>
  <div>
    <el-input
      v-if="
        data.componentName == 'input' ||
        data.componentName == 'input_number' ||
        data.componentName == 'mobile_input'
      "
      :placeholder="data.placeHolder"
      disabled
      ><i
        v-if="data.componentName == 'mobile_input'"
        slot="prepend"
        class="el-icon-mobile"
      >
      </i>
    </el-input>
    <el-input
      v-else-if="data.componentName == 'text_area'"
      type="textarea"
      :placeholder="data.placeHolder"
      disabled
    ></el-input>
    <el-date-picker
      v-else-if="data.componentName == 'date_picker'"
      type="data.picker"
      :placeholder="data.placeHolder"
      disabled
    ></el-date-picker>
    <el-select
      v-else-if="data.componentName == 'select'"
      :placeholder="data.placeHolder"
      v-model="selectValue"
      disabled
      @click.native="clickItem"
    >
      <template v-if="data.optionSource == 'custom'">
        <el-option
          v-for="(item, index) in data.customOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </template>
    </el-select>
    <el-radio-group v-else-if="data.componentName == 'radio_group'" disabled>
      <template v-if="data.optionSource == 'custom'">
        <el-radio
          v-for="item in data.customOptions"
          :key="item.value"
          :label="item.value"
          >{{ item.label }}
        </el-radio>
      </template>
    </el-radio-group>
    <el-checkbox-group
      v-model="checkboxValue"
      v-else-if="data.componentName == 'checkbox_group'"
      disabled
      style="height: 40px"
    >
      <template v-if="data.optionSource == 'custom'">
        <el-checkbox
          v-for="item in data.customOptions"
          :label="item.value"
          :key="item.value"
          >{{ item.label }}
        </el-checkbox>
      </template>
    </el-checkbox-group>
    <my-address
      v-else-if="data.componentName == 'address_input'"
      :picker="data.picker"
      disabled
    ></my-address>
    <div
      v-else-if="
        data.componentName == 'attachment_uploader' ||
        data.componentName == 'image_uploader'
      "
      class="sub-upload"
    >
      <i class="el-icon-upload"></i>
    </div>
    <sign v-else-if="data.componentName == 'sign_input'"></sign>
    <div v-else-if="data.componentName == 'sn_input'">
      <el-input placeholder="自动生成无需填写" disabled readonly></el-input>
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="data.componentName == 'user_select'"
      style="height: 32px; width: 100%"
    >
      选择成员
    </div>
    <div
      v-else-if="data.componentName == 'user_list_select'"
      class="outer-selected-user__empty-wrap"
      style="width: 100%; height: 32px"
    >
      选择成员
    </div>
    <div
      class="outer-selected-user__empty-wrap"
      v-else-if="data.componentName == 'department_select'"
      style="height: 32px; width: 100%"
    >
      选择部门
    </div>
    <div
      v-else-if="data.componentName == 'department_list_select'"
      class="outer-selected-user__empty-wrap"
      style="width: 100%; height: 32px"
    >
      选择部门
    </div>
  </div>
</template>
<script>
import MyAddress from "@/components/address";
import sign from "./sign";
export default {
  components: { MyAddress, sign },
  props: {
    data: Object,
  },
  data() {
    return {
      selectValue: "",
      checkboxValue: [],
    };
  },
  methods: {
    clickItem(event) {
      event.preventDefault();
      event.stopPropagation();
      this.$emit("changeCurrNode", this.data);
    },
  },
};
</script>
<style lang="scss" scoped>
.sub-upload {
  width: 160px;
  height: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  border: solid 1px #e4e7ed;
  border-radius: 5px;

  .el-icon-upload {
    color: #c0c4cc;
    font-size: 24px !important;
  }
}

.outer-selected-user__empty-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
}
</style>