var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "grid-table" },
    [
      _c(
        "colgroup",
        _vm._l(_vm.grid.columns, function (col, index) {
          return _c("col", { key: index, attrs: { width: col.width } })
        }),
        0
      ),
      _vm._l(_vm.grid.components, function (row) {
        return _c(
          "tr",
          { key: row.key },
          [
            _vm._l(row.components, function (col) {
              return [
                col.rowspan !== 0 && col.colspan !== 0
                  ? _c(
                      "td",
                      {
                        key: col.key,
                        style: _vm.borderStyle(col),
                        attrs: {
                          valign: col.valign ? col.valign : "middle",
                          align: col.align,
                          rowspan: col.rowspan,
                          colspan: col.colspan,
                        },
                      },
                      _vm._l(col.components, function (item) {
                        return _c("form-tpl-item", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !item.hidden,
                              expression: "!item.hidden",
                            },
                          ],
                          key: item.key,
                          attrs: {
                            isShowComponent: _vm.isShowComponent,
                            cols: _vm.cols,
                            form: _vm.form,
                            item: item,
                            "source-list": _vm.sourceList,
                            "area-tree": _vm.areaTree,
                            formType: _vm.formType,
                          },
                          on: {
                            changeFormData: _vm.changeFormData,
                            getMetaOptions: _vm.getMetaOptions,
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }