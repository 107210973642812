import request from '@/utils/request'
import qs from "qs"

/**
 * 保存表单
 * @param {*} data
 * @returns
 */
export function saveForm(data) {
    return request({
        url:  '/ui/form/save',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 获取表单分页列表
 * @param {*} data
 * @returns
 */
export function fetchFormList(data) {
    return request({
        url:  '/ui/form/list',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 获取表单详情
 * @param {*} formId
 * @returns
 */
export function getFormDetail(formId) {
    return request({
        url:  '/ui/form/detail',
        method: 'post',
        data: qs.stringify({ formId })
    })
}

/**
 * 保存组件
 * @returns
 */
export function saveComponent(data) {
    return request({
        url:  '/ui/form/saveComponent',
        method: 'post',
        data: data
    });
}



/**
 * 获取表单数据列表
 * @param {*} data
 * @returns
 */
export function fetchFormDataList(data) {
    return request({
        url:  '/formdata/list',
        method: 'post',
        data: qs.stringify(data)
    });
}

/**
 * 获取数据详情
 * @param {*} data
 * @returns
 */
export function getFormData(data) {
    return request({
        url:  '/formdata/detail',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 *
 * @param {*} data
 * @returns
 */
export function saveFormData(data) {
    return request({
        url:  '/formdata/save',
        method: 'post',
        data: qs.stringify(data)
    })
}


/**
 * 数据列表
 * @param {*} data
 * @returns
 */
export function fetchPage(data) {
    return request({
        url:  '/formdata/list',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 视图资源从低代码的视图列表接口
 * @returns
 */
export function fetchViewList(data) {
    return request({
        url:  '/ui/view/list',
        method: 'post',
        data
    })
}
/**
 * 元数据表
 * @returns
 */
export function fetchUiMetaTableList(data) {
    return request({
        url:  '/ui/meta/table/list',
        method: 'post',
        data
    })
}
/**
 * 元数据表字段
 * @returns
 */
export function fetchUiMetaFieldList(data) {
    return request({
        url:  '/ui/meta/field/list',
        method: 'post',
        data
    })
}
/**
 * 新建表单excel
 * @returns
 */
export function formCreateExcel(data) {
    return request({
        url:  '/ui/form/createExcel',
        method: 'post',
        data
    })
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelUpdateCell(data) {
    return request({
        url:  '/excel/updateCell',
        method: 'post',
        data
    })
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelEditCell(data) {
    return request({
        url:  '/excel/editCell',
        method: 'post',
        data
    })
}


export function deleteForm(data) {
    return request({
        url:  "/ui/form/delete",
        method: 'post',
        data
    })
}
export function formDuplicate(data) {
    return request({
        url:  "/ui/form/duplicate",
        method: 'post',
        data
    })
}
export function metaEnvCode(data) {
    return request({
        url:  "/ui/meta/envCode",
        method: 'post',
        data
    })
}

/**
 * 下载表单excel模板
 * @param data
 * @returns {*}
 */
export function excelExport(data) {
    return request({
        url:  "/excel/export",
        method: 'post',
        data
    })
}

/**
 * 下载表单word模板
 * @param data
 */
export function wordExport(data){
    return request({
        url:  "/app/appFormExportWord/download",
        method: 'post',
        data
    })
}

/**
 * 表单转标准群
 * @param data
 * @returns {*}
 */
export function transToPlatform(data){
    return request({
        url:  "/ui/form/transToPlatform",
        method: 'post',
        data
    })
}
/**
 * 跟视图创建接口一样，只是没有真正的创建任何视图数据
 *
 * @param data {
 *   componentName: 'table',
 *   title:"",
 *   dataSource: {
 *     formId: '',
 *     queryObject:{},
 *     sourceType: 'form',
 *   }
 * }
 * @returns {*}
 */
export function uiViewInit(data){
    return request({
        url:  "/ui/view/init",
        method: 'post',
        data
    })
}
/**
 * 获取视图列表
 * @param data {
 *   componentName: 'table',
      dataSourceFormId:""
 * }
 * @returns {*}
 */
export function uiViewList(data){
    return request({
        url:  "/ui/view/list",
        method: 'post',
        data
    })
}

/**
 * 获取模板列表
 * @param data
 */
export function getTemplateList(data) {
    return request({
        url: "/dev/wordsAndExcels/templateList",
        method: 'post',
        data
    })
}

/**
 * 重命名模板
 * @param data
 */
export function renameTemplate(data) {
    return request({
        url:  '/dev/wordsAndExcels/rename',
        method: 'post',
        data
    })
}
/**
 * 删除模板
 * @param data
 */
export function deleteTemplate(data) {
    return request({
        url:  '/dev/wordsAndExcels/delete',
        method: 'post',
        data
    })
}
/**
 * 单元格属性保存
 * @returns
 */
export function excelUpdateCellNew(data) {
    return request({
        url: '/excel/updateCellNew',
        method: 'post',
        data
    })
}
