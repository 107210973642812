import router from './router'
import store from './store'
import {Message} from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import {getRegistered, getToken} from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({showSpinner: false}) // NProgress Configuration

const whiteList = ['/login', '/reg'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    NProgress.start()

    document.title = getPageTitle(to.meta.title)

    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login'||to.path === '/reg') {
            // if is logged in, redirect to the home page
            next({path: '/'})
            NProgress.done()
        } else {

            const hasGetUserInfo = store.getters.name
            if (hasGetUserInfo&&hasGetUserInfo.trim()!=="") {
                next()
            } else {
                try {
                    // get user info
                    //todo 暂时无此接口
                    await store.dispatch('user/getInfo')
                    next()
                } catch (error) {
                    await store.dispatch('user/resetToken')
                    next(`/login?redirect=${to.fullPath}`)
                    NProgress.done()
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.fullPath}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
