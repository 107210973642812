<template>
  <reference-data-view
    :item="item"
    :form="form"
    :rules="rules"
    :cols="cols"
    v-if="item.componentName == 'reference_data' && isShowComponent(item)"
  ></reference-data-view>
  <preview-tabs
    v-else-if="item.componentName == 'tab'"
    v-show="!item.hidden"
    :cols="cols"
    :form="form"
    :data="item"
    :formType="formType"
    :source-list="sourceList"
    :area-tree="areaTree"
    :isShowComponent="isShowComponent"
    @changeFormData="changeFormData"
    @getMetaOptions="getMetaOptions"
  ></preview-tabs>

  <grid-view
    v-else-if="item.componentName == 'grid' && isShowComponent(item)"
    :cols="cols"
    :form="form"
    :grid="item"
    :source-list="sourceList"
    :area-tree="areaTree"
    :formType="formType"
    :isShowComponent="isShowComponent"
    @changeFormData="changeFormData"
    @getMetaOptions="getMetaOptions"
  ></grid-view>
  <div
    v-else-if="item.componentName == 'html' && isShowComponent(item)"
    v-html="item.description"
    style="float: left"
    :style="handleItemWidth(item)"
  ></div>
  <include-form
    v-else-if="item.componentName == 'include_form' && isShowComponent(item)"
    :item="item"
    :areaTree="areaTree"
    :form="form"
  ></include-form>

  <el-form-item
    :label="isShowLabel(item) ? item.title : ''"
    :style="handleItemWidth(item)"
    :prop="item.name"
    :class="{ 'un-label': !isShowLabel(item) }"
    :rules="rules"
    v-else-if="!arr.includes(item.componentName) && isShowComponent(item)"
  >
    <div v-html="item.description" style="color: #91a1b7"></div>
    <json-form
      v-if="item.componentName == 'json_form'"
      :item="item"
      :form="form"
      :formType="formType"
      :source-list="sourceList"
      :area-tree="areaTree"
      @changeFormData="changeFormData"
      @getMetaOptions="getMetaOptions"
    ></json-form>
    <form-item
      v-else
      :item="item"
      :form="form"
      :watchForm="form"
      :source-list="sourceList"
      :area-tree="areaTree"
      @changeFormData="changeFormData"
      @getMetaOptions="getMetaOptions"
    ></form-item>
  </el-form-item>
</template>
<script>
import JsonForm from "@/views/lowCode/form/component/JsonForm";
import test from "@/utils/test";
import ReferenceDataView from "./ReferenceDataView";
import IncludeForm from "./IncludeForm";

export default {
  components: { JsonForm, ReferenceDataView, IncludeForm },
  props: {
    cols: {
      type: Number,
      default() {
        return 1;
      },
    },
    formType: String,
    form: Object,
    item: Object,
    areaTree: Array,
    sourceList: {
      type: Object,
      default() {
        return {};
      },
    },
    isShowComponent: Function,
  },

  data() {
    return {
      rules: [],
      arr: ["tab", "grid", "reference_data", "label"],
    };
  },
  created() {
    let item = this.item;

    if (
      item.required ||
      item.componentName == "input_number" ||
      item.componentName == "mobile_input"
    ) {
      if (item.required) {
        this.rules.push({
          required: true,
          message: `${item.title}不能为空`,
        });
      }
      if (item.componentName == "input_number") {
        this.rules.push({
          validator: (rule, value, callback) => {
            if ((!item.required && test.empty(value)) || test.number(value)) {
              callback();
            } else {
              callback("请输入数字");
            }
          },
        });
      }
      if (item.componentName == "mobile_input") {
        this.rules.push({
          validator: (rule, value, callback) => {
            if (
              (!item.required && test.empty(value)) ||
              test.number(value) ||
              test.mobile(value)
            ) {
              callback();
            } else {
              callback("请输入手机号");
            }
          },
        });
      }
    }
  },
  methods: {
    /**
     * 处理表单项宽度
     *  如果表单项单项已经设置了宽度，则获取配置的数据，如果没有配置单项，则获取表单全局设置的列数来计算宽度
   
     * @param formItemConfig
     * @returns {string}
     */
    handleItemWidth(formItemConfig) {
      if (
        ["json_form", "tab", "grid", "reference_data"].includes(
          formItemConfig.componentName
        )
      ) {
        return `width:${100 * eval("1/1")}%;`;
      }
      if (!formItemConfig.extParam || !formItemConfig.extParam.customWidth) {
        return `width:${100 / this.cols}%;`;
      } else {
        return `width:${100 * eval(formItemConfig.extParam.customWidth)}%;`;
      }
    },
    isShowLabel(col) {
      return !col.hiddenTitle && (col.componentName == "form_data" || col.form);
    },
    changeFormData({ name, value, type, callback }) {
      this.$emit("changeFormData", { name, value, type, callback });
    },

    getMetaOptions(data) {
      // 获取select,radiogroup,checkGroup的关联其他表单数据
      this.$emit("getMetaOptions", data);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
