var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ref-container",
      on: { mouseenter: _vm.mouseenter, mouseleave: _vm.mouseleave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.actionVisible,
              expression: "actionVisible",
            },
          ],
          staticClass: "action-item",
        },
        [
          _c("i", {
            staticClass: "el-icon-copy-document",
            staticStyle: { "margin-right": "5px", cursor: "pointer" },
            attrs: { title: "复制" },
            on: {
              click: _vm.copyItem,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          }),
          _c("i", {
            staticClass: "el-icon-delete",
            staticStyle: { cursor: "pointer" },
            attrs: { title: "删除" },
            on: {
              click: _vm.removeItem,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          }),
        ]
      ),
      _c(
        "el-form-item",
        {
          class: { "un-label": !_vm.data.form },
          staticStyle: { width: "100%" },
          attrs: { label: _vm.label, required: !!_vm.data.required },
        },
        [_c("el-button", { attrs: { size: "small" } }, [_vm._v("选择数据")])],
        1
      ),
      _vm._l(_vm.data.columns, function (child) {
        return _c(
          "el-form-item",
          {
            key: child.field.name,
            class: { "un-label": !_vm.data.form },
            staticStyle: { width: "100%" },
          },
          [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _c("i", { staticClass: "el-icon-connection" }),
              _c("span", { domProps: { textContent: _vm._s(child.title) } }),
            ]),
            child.field.componentName != "json_form"
              ? _c("el-input", {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "暂无内容",
                    disabled: "",
                    size: "small",
                  },
                })
              : _c(
                  "el-table",
                  { staticStyle: { width: "100%" }, attrs: { data: [] } },
                  _vm._l(child.subColumns, function (col) {
                    return _c("el-table-column", {
                      key: col.field.name,
                      attrs: { "min-width": "120px", label: col.title },
                    })
                  }),
                  1
                ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }