var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _c(
          "div",
          { ref: "jsonForm", staticClass: "json-form" },
          [
            _c(
              "div",
              { staticClass: "json-row json-head" },
              [
                _c("div", {
                  staticClass: "json-cell",
                  staticStyle: { width: "100px" },
                }),
                _c(
                  "div",
                  {
                    staticClass: "json-action",
                    staticStyle: { width: "100px" },
                    style: `transform: translateX(${_vm.left}px);`,
                  },
                  [_vm._v(" 操作 ")]
                ),
                _vm._l(_vm.componentList, function (col) {
                  return [
                    col.componentName == "reference_data"
                      ? _c(
                          "div",
                          {
                            key: col.key,
                            staticClass: "json-ref json-head-cell",
                            style: `width:${_vm.componentWidth[col.key]}px;`,
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "json-left-cell",
                                style: _vm.getLeftWidth(col),
                              },
                              [
                                _c("span", {
                                  domProps: {
                                    textContent: _vm._s(
                                      col.columns && col.columns.length
                                        ? ""
                                        : col.title
                                        ? col.title
                                        : col.label
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._l(col.columns, function (child) {
                              return _c(
                                "div",
                                {
                                  key: child.field.name,
                                  staticClass: "json-ref-cell",
                                  style: `width:${
                                    _vm.componentWidth[
                                      col.key + "." + child.field.name
                                    ]
                                  }px;`,
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-connection",
                                  }),
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(child.title),
                                    },
                                  }),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            key: col.key,
                            staticClass: "json-head-cell",
                            style: `width:${_vm.componentWidth[col.key]}px;`,
                          },
                          [
                            col.required
                              ? _c(
                                  "span",
                                  { staticStyle: { color: "#f56c6c" } },
                                  [_vm._v("*")]
                                )
                              : _vm._e(),
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  col.title ? col.title : col.label
                                ),
                              },
                            }),
                          ]
                        ),
                  ]
                }),
              ],
              2
            ),
            _vm.form[_vm.item.name] && _vm.form[_vm.item.name].length
              ? _c(
                  "draggable",
                  {
                    staticClass: "json-body",
                    attrs: {
                      list: _vm.form[_vm.item.name],
                      animation: "300",
                      handle: ".move",
                    },
                  },
                  _vm._l(_vm.form[_vm.item.name], function (row, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "json-row" },
                      [
                        _c("div", {
                          staticClass: "json-cell",
                          staticStyle: { width: "100px" },
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "json-action",
                            staticStyle: { width: "100px" },
                            style: `transform: translateX(${_vm.left}px);`,
                          },
                          [
                            _c("el-button", {
                              staticClass: "move",
                              staticStyle: {
                                padding: "8px",
                                border: "0px",
                                width: "30px",
                                height: "30px",
                              },
                              attrs: { icon: "el-icon-rank" },
                            }),
                            _c("el-button", {
                              staticStyle: {
                                padding: "8px",
                                border: "0px",
                                width: "30px",
                                height: "30px",
                                "margin-left": "0px",
                              },
                              attrs: { icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteRow(
                                    index,
                                    _vm.form[_vm.item.name],
                                    _vm.item
                                  )
                                },
                              },
                            }),
                            _c(
                              "el-popover",
                              {
                                staticClass: "row-more",
                                attrs: {
                                  "popper-class": "pop-over",
                                  width: "150",
                                  trigger: "hover",
                                },
                                model: {
                                  value: _vm.visibleObj[index],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.visibleObj, index, $$v)
                                  },
                                  expression: "visibleObj[index]",
                                },
                              },
                              [
                                _c("el-button", {
                                  class: { active: _vm.visibleObj[index] },
                                  staticStyle: {
                                    padding: "8px",
                                    border: "0px",
                                    width: "30px",
                                    height: "30px",
                                  },
                                  attrs: {
                                    slot: "reference",
                                    icon: "el-icon-more",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRow(
                                        index,
                                        _vm.form[_vm.item.name],
                                        _vm.item
                                      )
                                    },
                                  },
                                  slot: "reference",
                                }),
                                _c("div", { staticClass: "row-pop" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyRow(index, "next")
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制到下一行 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyRow(index, "last")
                                        },
                                      },
                                    },
                                    [_vm._v(" 复制到最后一行 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRow(-1, index)
                                        },
                                      },
                                    },
                                    [_vm._v(" 向上插入一行 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "row-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addRow(1, index)
                                        },
                                      },
                                    },
                                    [_vm._v(" 向下插入一行 ")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.componentList, function (col) {
                          return [
                            col.componentName == "reference_data"
                              ? _c("reference-data-view", {
                                  key: col.key,
                                  staticClass: "json-ref",
                                  style: `width:${
                                    _vm.componentWidth[col.key]
                                  }px;`,
                                  attrs: {
                                    item: col,
                                    form: _vm.getForm(row),
                                    componentList: _vm.item.components,
                                    row: row,
                                    inJsonForm: true,
                                    columnsWidth: _vm.componentWidth,
                                    disabled: _vm.isInitValue,
                                  },
                                  on: {
                                    changeFormData: function ($event) {
                                      return _vm.changeFormData(index, $event)
                                    },
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    key: col.key,
                                    staticClass: "json-row-cell",
                                    style: `width:${
                                      _vm.componentWidth[col.key]
                                    }px;`,
                                  },
                                  [
                                    _c("form-item", {
                                      ref: `formItem_${col.name}_${index}`,
                                      refInFor: true,
                                      attrs: {
                                        item: col,
                                        form: row,
                                        parentForm: _vm.form,
                                        watchForm: _vm.getForm(row),
                                        "source-list": _vm.sourceList,
                                        "area-tree": _vm.areaTree,
                                        isJsonFormItem: true,
                                        isInitValue: true,
                                        rowIndex: index,
                                        parentName: _vm.item.name,
                                      },
                                      on: {
                                        changeFormData: function ($event) {
                                          return _vm.changeFormData(
                                            index,
                                            $event
                                          )
                                        },
                                        getMetaOptions: _vm.getMetaOptions,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                          ]
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "json-body" }, [
                  _c(
                    "div",
                    {
                      staticClass: "el-table__empty-text",
                      staticStyle: { "text-align": "center", width: "100%" },
                    },
                    [_vm._v(" 暂无数据 ")]
                  ),
                ]),
          ],
          1
        ),
      ]),
      _c(
        "el-button",
        {
          staticStyle: { "margin-top": "10px" },
          attrs: { type: "text", icon: "el-icon-plus" },
          on: {
            click: function ($event) {
              return _vm.addRow(0)
            },
          },
        },
        [_vm._v("添加 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }