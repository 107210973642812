var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.componentName == "input" ||
    _vm.item.componentName == "input_number" ||
    _vm.item.componentName == "mobile_input"
    ? _c(
        "el-input",
        {
          attrs: {
            placeholder: _vm.item.placeHolder,
            readonly: !!_vm.item.readonly,
          },
          on: {
            blur: function ($event) {
              return _vm.blurInput($event, -1)
            },
          },
          model: {
            value: _vm.form[_vm.item.name],
            callback: function ($$v) {
              _vm.$set(_vm.form, _vm.item.name, $$v)
            },
            expression: "form[item.name]",
          },
        },
        [
          _vm.item.componentName == "input_number" &&
          _vm.item.format == "percent"
            ? _c("template", { slot: "append" }, [_vm._v("% ")])
            : _vm._e(),
          _vm.item.componentName == "mobile_input"
            ? _c("i", {
                staticClass: "el-icon-mobile",
                attrs: { slot: "prepend" },
                slot: "prepend",
              })
            : _vm._e(),
        ],
        2
      )
    : _vm.item.componentName == "text_area"
    ? _c("el-input", {
        attrs: {
          type: "textarea",
          autosize: { minRows: 3 },
          placeholder: _vm.item.placeHolder,
          readonly: !!_vm.item.readonly,
        },
        model: {
          value: _vm.form[_vm.item.name],
          callback: function ($$v) {
            _vm.$set(_vm.form, _vm.item.name, $$v)
          },
          expression: "form[item.name]",
        },
      })
    : _vm.item.componentName == "date_picker"
    ? _c("el-date-picker", {
        attrs: {
          type: _vm.item.picker,
          placeholder: _vm.item.placeHolder,
          disabled: _vm.item.readonly,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : _vm.item.componentName == "select"
    ? _c(
        "el-select",
        {
          directives: [
            {
              name: "loadmore",
              rawName: "v-loadmore",
              value: _vm.loadMore,
              expression: "loadMore",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            placeholder: _vm.item.placeHolder,
            disabled: !!_vm.item.readonly,
            filterable: true,
            remote: _vm.item.optionSource == "metaField",
            loading: _vm.loading,
            "remote-method": _vm.searchList,
            "popper-class": !!(
              _vm.item.optionSource == "metaField" &&
              _vm.item.metaFieldOption.tableName &&
              _vm.item.metaFieldOption.tableColumns &&
              _vm.item.metaFieldOption.tableColumns.length
            )
              ? "metaSelect"
              : "",
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (opt, index) {
          return _c(
            "el-option",
            { key: index, attrs: { label: opt.label, value: opt.value } },
            [
              _vm.item.optionSource == "metaField" &&
              _vm.item.metaFieldOption.tableName &&
              _vm.item.metaFieldOption.tableColumns &&
              _vm.item.metaFieldOption.tableColumns.length
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "opt-item",
                        style: `width:${_vm.width ? _vm.width : ""}px;`,
                      },
                      [
                        _c("div", {
                          staticClass: "opt-title",
                          domProps: { textContent: _vm._s(opt.label) },
                        }),
                        _c("div", {
                          staticClass: "sub-title",
                          domProps: {
                            textContent: _vm._s(_vm.getSubTitle(opt)),
                          },
                        }),
                      ]
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        1
      )
    : _vm.item.componentName == "select_checkbox"
    ? _c(
        "el-select",
        {
          attrs: {
            placeholder: _vm.item.placeHolder,
            disabled: !!_vm.item.readonly,
            multiple: "",
            filterable: "",
            "collapse-tags": "",
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (opt, index) {
          return _c("el-option", {
            key: index,
            attrs: { label: opt.label, value: opt.value },
          })
        }),
        1
      )
    : _vm.item.componentName == "radio_group"
    ? _c(
        "el-radio-group",
        {
          attrs: { disabled: !!_vm.item.readonly },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (opt, index) {
          return _c("el-radio", { key: index, attrs: { label: opt.value } }, [
            _vm._v(_vm._s(opt.label) + " "),
          ])
        }),
        1
      )
    : _vm.item.componentName == "checkbox_group"
    ? _c(
        "el-checkbox-group",
        {
          attrs: { disabled: !!_vm.item.readonly },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        _vm._l(_vm.options, function (opt, index) {
          return _c(
            "el-checkbox",
            { key: index, attrs: { label: opt.value } },
            [_vm._v(_vm._s(opt.label) + " ")]
          )
        }),
        1
      )
    : _vm.item.componentName == "divider"
    ? _c(
        "my-divider",
        {
          attrs: {
            "line-type": _vm.item.lineType,
            "title-align": _vm.item.titleAlign,
            dashed: _vm.item.dashed,
            "line-color": _vm.item.lineColor,
          },
        },
        [_vm._v(_vm._s(_vm.item.title) + " ")]
      )
    : _vm.item.componentName == "sign_input"
    ? _c("sign")
    : _vm.item.componentName == "form_data"
    ? _c("form-data", {
        ref: "form_data",
        attrs: { column: _vm.item, form: _vm.form },
      })
    : _vm.item.componentName == "address_input"
    ? _c("my-address", {
        attrs: {
          picker: _vm.item.picker,
          "area-tree": _vm.areaTree,
          disabled: !!_vm.item.readonly,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : ["attachment_uploader", "image_uploader"].includes(_vm.item.componentName)
    ? _c(
        "div",
        [
          _vm.isInitValue
            ? _c("form-upload", {
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _c("el-button", { attrs: { type: "primary", size: "mini" } }, [
                _vm._v(
                  _vm._s(
                    _vm.item.componentName == "attachment_uploader"
                      ? "上传附件"
                      : "上传图片"
                  ) + " "
                ),
              ]),
        ],
        1
      )
    : _vm.item.componentName == "sn_input"
    ? _c("el-input", {
        attrs: {
          placeholder: "自动生成无需填写",
          readonly: !!_vm.item.readonly,
          disabled: "",
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : _vm.item.componentName == "department_select"
    ? _c(
        "div",
        {
          staticClass: "outer-selected-user__empty-wrap",
          staticStyle: { height: "40px", width: "100%" },
        },
        [_vm._v(" 选择部门 ")]
      )
    : _vm.item.componentName == "department_list_select"
    ? _c(
        "div",
        {
          staticClass: "outer-selected-user__empty-wrap",
          staticStyle: { height: "40px", width: "100%" },
        },
        [_vm._v(" 选择部门 ")]
      )
    : ["user_select", "user_list_select"].includes(_vm.item.componentName)
    ? _c(
        "div",
        {
          staticClass: "outer-selected-user__empty-wrap",
          staticStyle: { height: "40px", width: "100%" },
        },
        [_vm._v(" 选择用户 ")]
      )
    : _vm.item.componentName == "position_input"
    ? _c("div", { staticClass: "fui_location" }, [
        _c("i", { staticClass: "el-icon-location-information" }),
        _c("span", [_vm._v("获取定位")]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }