var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.value.length
        ? _c(
            "div",
            {
              staticClass: "upload",
              on: {
                click: function ($event) {
                  _vm.visible = true
                },
              },
            },
            [
              _c("div", {
                staticClass: "label",
                domProps: { textContent: _vm._s(_vm.label) },
              }),
              _c("i", { staticClass: "el-icon-upload" }),
            ]
          )
        : _c(
            "div",
            { staticClass: "att-list" },
            _vm._l(_vm.value, function (attachment, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "attachment-icon",
                  attrs: { title: _vm.getTitle(attachment) },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [
                  _vm.checkFileImage(attachment.extension)
                    ? _c("el-image", {
                        ref: "elImg_" + index,
                        refInFor: true,
                        staticClass: "attachment-image",
                        attrs: {
                          "z-index": 99999,
                          src:
                            attachment.url +
                            "?x-oss-process=image/resize,m_lfit,h_40,w_40",
                        },
                      })
                    : _vm._e(),
                  !_vm.checkFileImage(attachment.extension)
                    ? _c("SvgIcon", {
                        staticClass: "attachment-svg",
                        attrs: {
                          "icon-class": _vm.getIconClassByExtension(
                            attachment.extension
                          ),
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.fileType == "image" ? "图片列表" : "附件列表",
            visible: _vm.dialogVisible,
            width: "600px",
            "append-to-body": true,
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _vm.isShowUpload && !_vm.disabled
                ? _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.visible = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.fileType == "image" ? "上传图片" : "上传附件"
                        ) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("attachment-list", {
                attrs: { "attachment-list": _vm.value },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              icon: "el-icon-paperclip",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleAttachmentDownload(scope.row)
                              },
                            },
                          },
                          [_vm._v("下载 ")]
                        ),
                        _vm.canPreview(scope.row.extension)
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-view",
                                  href: scope.row.url,
                                  target: "_blank",
                                  download: scope.row.originalName,
                                },
                              },
                              [_vm._v("查看 ")]
                            )
                          : _vm._e(),
                        _c(
                          "el-link",
                          {
                            attrs: { type: "danger", icon: "el-icon-delete" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAttachmentDelete(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.label,
            visible: _vm.visible,
            width: "600px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "20px" } },
            [
              _c("upload", {
                ref: "fileUpload",
                attrs: { accept: _vm.accept, limit: _vm.limitFileCount },
                on: { change: _vm.handleUpLoadChange },
              }),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addUploadFiles },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }