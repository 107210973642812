import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    name: "登录",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  // {
  //   path: "/reg",
  //   name: "注册",
  //   component: () => import("@/views/reg/index"),
  //   hidden: true,
  // },

  {
    path: "/404",
    component: () => import("@/views/error-page/404"),
    hidden: true,
  },

  {
    path: "/",
    component: Layout,
    redirect: "/templateList/index",
  },
  {
    path: "/OA",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index",
        name: "OA设置",
        component: () => import("@/views/OA/index"),
        meta: { title: "OA设置", icon: "form2" },
      },
    ],
  },
  {
    path: "/boardForm",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "index",
        name: "看板表单设置",
        component: () => import("@/views/boardForm/index"),
        meta: { title: "看板表单设置", icon: "form2" },
      },
    ],
  },
  {
    path: "/templateList",
    component: Layout,
    children: [
      {
        path: "index",
        name: "表格模板",
        component: () =>
          import("@/views/excelLibManage/excelTemplateNew/index"),
        meta: { title: "平台模板", icon: "excel" },
      },
    ],
  },

  {
    path: "/lowCode",
    component: Layout,
    meta: { title: "零代码", icon: "menu" },
    redirect: "noRedirect",
    alwaysShow: true,
    children: [
      {
        path: "form",
        meta: { title: "表单设计", icon: "form2" },
        component: () => import("@/views/lowCode/form/index"),
        children: [
          {
            path: "edit",
            meta: { title: "表单界面设计" },
            component: () => import("@/views/lowCode/form/edit"),
            hidden: true,
          },
          {
            path: "templateSetting",
            name: "表单模版绑定",
            meta: { title: "表单模版绑定" },
            component: () => import("@/views/lowCode/form/templateSetting"),
            hidden: true,
            children: [
              {
                path: "excel",
                name: "绑定表格",
                meta: { title: "绑定表格" },
                component: () =>
                  import(
                    "@/views/lowCode/form/templateSetting/excel/excelEdit"
                  ),
                hidden: true,
              },
              {
                path: "word",
                name: "绑定文档",
                meta: { title: "绑定文档" },
                component: () =>
                  import("@/views/lowCode/form/templateSetting/word/index"),
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        path: "view",
        name: "视图设计",
        meta: { title: "视图设计", icon: "form2" },
        component: () => import("@/views/lowCode/view/index"),
        hidden: false,
        children: [
          {
            path: "edit",
            meta: { title: "视图界面设计" },
            component: () => import("@/views/lowCode/view/edit"),
            hidden: true,
          },
          {
            path: "chart",
            meta: { title: "图表设计" },
            component: () => import("@/views/lowCode/view/chart"),
            hidden: true,
          },
          {
            path: "aggregate",
            meta: { title: "聚合表" },
            component: () => import("@/views/lowCode/view/aggregate"),
            hidden: true,
          },
          {
            path: "gantt",
            meta: { title: "甘特图" },
            component: () => import("@/views/lowCode/view/gantt"),
            hidden: true,
          },
          {
            path: "specialView",
            meta: { title: "特殊视图" },
            component: () => import("@/views/lowCode/view/specialView"),
            hidden: true,
          },
          {
            path: "treeView",
            meta: { title: "树形视图" },
            component: () => import("@/views/lowCode/view/treeView"),
            hidden: true,
          },
        ],
      },
      {
        path: "view/chart",
        name: "图表设计",
        meta: { title: "图表设计" },
        component: () => import("@/views/lowCode/view/chart"),
        hidden: true,
      },
      {
        path: "dashboard",
        name: "仪表盘",
        meta: { title: "仪表盘", icon: "form2" },
        component: () => import("@/views/lowCode/dashboard/dashboard-index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "编辑仪表盘",
            meta: { title: "编辑仪表盘" },
            component: () => import("@/views/lowCode/dashboard/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "assistant",
        name: "智能助手",
        meta: { title: "智能助手", icon: "form2" },
        component: () => import("@/views/lowCode/assistant/assistant-index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "智能助手编辑",
            meta: { title: "智能助手编辑", icon: "form2" },
            component: () => import("@/views/lowCode/assistant/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "plugins",
        name: "插件",
        meta: { title: "插件", icon: "form2" },
        component: () => import("@/views/lowCode/plugins/index"),
        hidden: false,
        children: [
          {
            path: "edit",
            name: "插件编辑",
            meta: { title: "插件编辑", icon: "form2" },
            component: () => import("@/views/lowCode/plugins/edit"),
            hidden: true,
          },
        ],
      },
      {
        path: "plugins/log",
        name: "插件日志",
        meta: { title: "插件日志", icon: "form2" },
        component: () => import("@/views/lowCode/plugins/log"),
        hidden: false,
      },
    ],
  },
  {
    path: "/excelLibrary",
    component: Layout,
    meta: { title: "表格库", icon: "table" },
    children: [
      {
        path: "list",
        name: "表格管理",
        component: () => import("@/views/excelLibManage/index"),
        meta: { title: "表格管理", icon: "excel" },
      },
      {
        path: "category",
        name: "类目管理",
        component: () => import("@/views/excelLibManage/category"),
        meta: { title: "类目管理", icon: "category" },
      },
      {
        path: "/excelSetting",
        name: "表格设置",
        component: () =>
          import("@/views/excelLibManage/excelSetting/excelSetting"),
        meta: { title: "表格设置", icon: "category" },
      },
    ],
  },
  {
    path: "/company/manage",
    component: Layout,
    children: [
      {
        path: "index",
        name: "单位管理",
        component: () => import("@/views/company/manage/index"),
        meta: { title: "单位管理", icon: "excel" },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    meta: { title: "用户管理", icon: "table" },
    children: [
      {
        path: "analyse",
        name: "用户分析",
        component: () => import("@/views/user/analyse/index"),
        meta: { title: "用户分析", icon: "excel" },
      },
      {
        path: "list",
        name: "用户列表",
        component: () => import("@/views/user/list/index"),
        meta: { title: "用户列表", icon: "category" },
      },
      {
        path: "userActionLog",
        name: "用户操作日志",
        component: () => import("@/views/user/userOperationLog/index"),
        meta: { title: "用户操作日志", icon: "edit-line" },
      },
      {
        path: "userLoginLog",
        name: "用户登录日志",
        component: () => import("@/views/user/userLoginLog/index"),
        meta: { title: "用户登录日志", icon: "yanshou" },
      },
      {
        path: "userBrowserLog",
        name: "用户浏览器信息",
        component: () => import("@/views/user/userBrowserLog/index"),
        meta: { title: "用户浏览器信息", icon: "edit-line" },
      },
    ],
  },

  {
    path: "/systemSetting",
    component: Layout,
    meta: { title: "系统设置", icon: "Tools" },
    alwaysShow: true,
    children: [
      {
        path: "wordManagement",
        name: "敏感词管理",
        component: () => import("@/views/systemSetting/wordManagement/index"),
        meta: { title: "敏感词管理", icon: "jiance2" },
      },
      {
        path: "taskList",
        name: "功能列表",
        component: () => import("@/views/systemSetting/taskList/index"),
        meta: { title: "功能列表", icon: "jiance2" },
      },
      {
        path: "roleAuthSetting",
        name: "运营权限设置",
        component: () =>
          import("@/views/systemSetting/roleAuthSetting/role-auth-setting.vue"),
        meta: { title: "运营权限设置", icon: "jiance2" },
      },
    ],
  },
  {
    path: "/sceneCode",
    component: Layout,
    children: [
      {
        path: "index",
        name: "场景码生成",
        component: () => import("@/views/sceneCode/scene-code-index.vue"),
        meta: { title: "场景码生成", icon: "excel" },
      },
    ],
  },
  {
    path: "/signApplyReview",
    component: Layout,
    children: [
      {
        path: "index",
        name: "签章申请审核",
        component: () => import("@/views/signApplyReview/signApplyReview.vue"),
        meta: { title: "签章申请审核", icon: "gaizhang" },
      },
    ],
  },
  {
    path: "/redirect/online/:formId/edit",
    name: "表单数据详情",
    component: () => import("@/views/lowCode/edit"),
  },
  {
    path: "/thirdPlatFormConfig",
    component: Layout,
    children: [
      {
        path: "index",
        name: "集成配置",
        component: () => import("@/views/thirdPlatFormConfig/index.vue"),
        meta: { title: "集成配置", icon: "excel" },
      },
    ],
  },
  // 下面四个路由是通过运营中心iframe跳转过来的路由配置
  {
    path: "/no/form/edit",
    meta: { title: "表单界面设计-noLayOut" },
    component: () => import("@/views/lowCode/form/edit"),
    hidden: true,
  },

  {
    path: "/no/view/edit",
    name: "视图设计-noLayOut",
    meta: { title: "视图设计", icon: "form2" },
    component: () => import("@/views/lowCode/view/edit"),
    hidden: true,
  },

  {
    path: "/redirect/board/:bulletinId",
    name: "看板预览",
    component: () =>
      import("@/views/excelLibManage/excelTemplateNew/component/BoardDetail2"),
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
