
import { fetchSysFields } from "@/api/form";

export default {
    namespaced: true,
    state: {
        sysFields: [],
        sysFieldNames: []

    },
    actions: {
        fetchSysFields({ commit }) {
            fetchSysFields().then(res => {
                commit("SET_SYS_FIELDS", res.data.list)
            })
        }
    },
    mutations: {
        SET_SYS_FIELDS: (state, list) => {
            let sysFields = list.map(item => item.component);
            sysFields.forEach(item => {
                item.key = item.name;
            });
            state.sysFields = sysFields
            state.sysFieldNames = sysFields.map(item => item.name)

        }
    }
}
