var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-cascader", {
        staticStyle: { width: "100%" },
        attrs: {
          disabled: _vm.disabled,
          options: _vm.areaList,
          placeholder: "请选择地址",
          props: _vm.areaTreeProp,
        },
        model: {
          value: _vm.area,
          callback: function ($$v) {
            _vm.area = $$v
          },
          expression: "area",
        },
      }),
      _vm.picker == "address"
        ? _c("el-input", {
            staticStyle: { width: "100%", "margin-top": "10px" },
            attrs: {
              type: "textarea",
              placeholder: "请填写详细地址",
              rows: 2,
              resize: "none",
              disabled: _vm.disabled,
            },
            model: {
              value: _vm.address,
              callback: function ($$v) {
                _vm.address = $$v
              },
              expression: "address",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }