var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-divider", class: `is-${_vm.titleAlign}` },
    [
      _vm.lineType == "horizontal"
        ? _c("span", { staticClass: "divider-text" }, [_vm._t("default")], 2)
        : _vm._e(),
      _c("div", {
        staticClass: "line",
        class: `line-${_vm.lineType}`,
        style: _vm.style,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }