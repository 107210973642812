import { advanceFields } from '@zgg-core-utils/whiteList';
import { isEmpty } from '@zgg-core-utils/utils';
import _ from 'lodash'

export function isJsonValue(name) {
  let array = ['checkbox_group', 'json_form', 'image_uploader', 'attachment_uploader', 'address_input'];
  return array.includes(name);
}

export function isArrayValue(name) {
  let array = ['checkbox_group', 'json_form', 'image_uploader', 'attachment_uploader'];
  return array.includes(name);
}

export function isSortableComponent(name) {
  let array = ['input', 'text_area', 'input_number', 'date_picker', 'select', 'sn_input', 'radio_group'];
  return array.includes(name);
}

/**
 * 获取非容器的表单组件
 */
export function getFormComponents(componentList) {
  let list = [];
  if (componentList) {
    componentList.filter(() => true).forEach((row) => {
      if (row.form) {
        list.push(row);
      } else if (row.container) {
        list = list.concat(getFormComponents(row.components));
      }

    });
  }


  return list;
}

/**
 * 判断组件是否存在
 * @param {*} fieldName
 * @param {*} componentList
 */
export function existInComponentsByName(fieldName, componentList) {
  //因关联数据表格列字段和搜索字段现在允许通过视图直接来读取，所以有可能存在如下三个字段固定字段，如遇如下3个字段，直接认为存在，返回true
  if (isEmpty(fieldName)) {
    return true
  }
  const alwaysExistFieldName = [
    "createdTime",
    "createrName",
    "updatedTime"
  ]
  if (alwaysExistFieldName.includes(fieldName)) {
    return true
  }
  let list = getFormComponents(componentList);

  let result = false;
  if (fieldName) {
    if (fieldName.indexOf(".") == -1) {
      // 非子表单
      result =
        list.findIndex((row) => row.name == fieldName) >=
        0;
      if (!result) {
        let list2 = list.filter(row => row.componentName == 'json_form')
        result = list2.findIndex(item => item.components.findIndex((row) => row.name == fieldName) >= 0) >= 0;
      }
    } else {
      // 子表单
      let names = fieldName.split(".");
      result =
        list.findIndex(
          (row) =>
            row.name == names[0] &&
            row.components.findIndex(
              (child) => child.name == names[1]
            ) >= 0
        ) >= 0;
    }
  }
  return result;


}

/**
 * 传入field来判断组件是否存在
 * 此方法目前为了处理配置了关联数据内的字段时，无法通过existInComponentsByName校验
 * @param {*} fieldName
 * @param {*} componentList
 */
export function existInComponentsByField({ fieldName, referenceName }, componentList) {
  //todo 此处需要再处理关联数据内的子表单的字段校验
  // const fieldName = field.field.name
  // const referenceName = field.referenceName
  if (fieldName == 'flowStatus') {
    return true;
  }
  if (referenceName) {
    let allFormFieldList = getFormComponents(componentList);
    let findIndex = allFormFieldList.findIndex(field => field.name === referenceName)
    const referenceField = findIndex >= 0 && allFormFieldList[findIndex]
    if (referenceField) {

      return referenceField.columns.findIndex(column => column.field.name === fieldName) !== -1
    }
    else {
      return false
    }
  } else {
    return existInComponentsByName(fieldName, componentList)
  }


}


/**
 * 获取表单中允许成为过滤条件的组件列表
 * @param {*} componentList
 * @returns
 */
export function getAdvanceFieldList(componentList) {

  let list = [];

  let callback = (components, parent) => {
    components.forEach((item) => {
      if (item.container) {
        callback(item.components, item);
      } else if (item.componentName == "reference_data") {
        if (!parent) {
          list.push(_.cloneDeep(item));
          item.columns.forEach((col) => {
            let obj = _.cloneDeep(col.field.component);
            // 关联数据的子表单字段不进入过滤条件
            if (
              obj.componentName != "json_form" &&
              advanceFields.includes(obj.componentName)
            ) {
              obj.title = item.title + "." + col.title;
              obj.collection = item.tableName;
              obj.referenceName = item.name;
              obj.parent = _.cloneDeep(item);
              list.push(obj);
            }
          });
        }
      } else if (item.componentName == "form_data") {
        item.columns.forEach((col) => {
          if (
            col.field.componentName != "json_form" &&
            advanceFields.includes(col.field.componentName)
          ) {
            let obj;
            if (col.field.component) {
              obj = _.cloneDeep(col.field.component);
            } else {
              obj = _.cloneDeep(col.field);
            }
            obj.title = item.title + "." + col.title;
            obj.collection = item.tableName;
            obj.referenceName = item.key;
            obj.parent = _.cloneDeep(item);
            list.push(obj);
          }
        });
      } else if (item.form) {
        if (advanceFields.includes(item.componentName)) {
          let obj = _.cloneDeep(item);
          if (parent && parent.componentName == "json_form") {
            obj.name = parent.name + "." + obj.name;
            obj.title = parent.title + "." + obj.title;
            obj.parent = _.cloneDeep(parent);
          }
          list.push(obj);
        }
      }
    });
  };
  callback(componentList);

  return list;
}


/**
 * 获取表单中允许成为过滤条件值的组件列表
 * @param {*} componentList
 * @returns
 */
export function getAdvanceValueList(componentList) {

  let list = [];

  let callback = (components, parent) => {
    components.forEach((item) => {
      if (item.container) {
        callback(item.components, item);
      } else if (item.componentName == "reference_data") {
        if (!parent) {
          list.push(_.cloneDeep(item));

        }
      } else if (item.form) {
        if (advanceFields.includes(item.componentName)) {
          let obj = _.cloneDeep(item);
          if (parent && parent.componentName == "json_form") {
            obj.name = obj.name;
            obj.title = parent.title + "." + obj.title;
            obj.parent = _.cloneDeep(parent);
          }
          list.push(obj);
        }
      }
    });
  };
  callback(componentList);

  return list;
}

export function isExportFileName(name) {
  let array = ['input', 'input_number', 'date_picker', 'select', 'radio_group', "mobile_input", "user_select", "department_select"];
  return array.includes(name);
}
