import Cookies from 'js-cookie'

const TokenKey = 'zhiguangong-admin-token'
const RegisteredFlag = 'RegisteredFlag'
//添加作用域
const DOMAIN = process.env.VUE_APP_DOMAIN
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  //移除cookies
  return Cookies.remove(TokenKey)
}
//获取注册状态
export function getRegistered() {
  return Cookies.get(RegisteredFlag)
}
//设置注册状态
export function setRegistered(token) {

  return Cookies.set(RegisteredFlag, token)
}
//移除注册状态
export function removeRegistered() {
  //移除cookies
  return Cookies.remove(RegisteredFlag)
}
