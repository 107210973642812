var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "el-form-item tabs",
      on: {
        mouseenter: _vm.mouseenter,
        mouseleave: _vm.mouseleave,
        mousedown: _vm.mousedown,
      },
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.data.components, function (item, index0) {
          return _c(
            "el-tab-pane",
            { key: index0, attrs: { label: item.title, name: index0 + "" } },
            [
              _c(
                "draggable",
                {
                  staticStyle: {
                    "min-height": "200px",
                    display: "flex",
                    "flex-wrap": "wrap",
                  },
                  attrs: {
                    list: item.components,
                    group: _vm.colGroup2,
                    animation: "700",
                    "scroll-sensitivity": 500,
                    "fallback-tolerance": "500",
                    "scroll-speed": 1000,
                    forceFallback: false,
                  },
                  on: {
                    choose: _vm.chooseDrag,
                    end: _vm.dragEnd,
                    add: _vm.onAdd,
                  },
                },
                [
                  _vm._l(item.components, function (col, cIndex) {
                    return [
                      col.componentName == "reference_data"
                        ? _c("reference-data", {
                            key: `${col.key}`,
                            ref: `cpt_${col.key}`,
                            refInFor: true,
                            staticClass: "custom-form-item",
                            class: {
                              "is-checked": _vm.currentKey == col.key,
                              "copy-move": col.isCopyMove,
                            },
                            style: _vm.handleItemWidth(col),
                            attrs: {
                              index: cIndex,
                              data: col,
                              isDrag: _vm.isDrag,
                            },
                            on: {
                              removeItem: _vm.removeTabItem,
                              copyItem: _vm.copyTabItem,
                              changeCurrNode: _vm.changeCurrNode,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.changeCurrNode(col, $event)
                              },
                            },
                          })
                        : col.componentName == "grid"
                        ? _c("edit-grid", {
                            key: col.key,
                            ref: `cpt_${col.key}`,
                            refInFor: true,
                            staticClass: "custom-form-item",
                            class: {
                              "is-checked": _vm.currentKey == col.key,
                              "is-error": !!_vm.getError(col) && _vm.isSave,
                              "copy-move": col.isCopyMove,
                            },
                            attrs: {
                              component: col,
                              index: cIndex,
                              put: _vm.put,
                              isDrag: _vm.isDrag,
                              cols: _vm.cols,
                              getError: _vm.getError,
                              buildCopyItem: _vm.buildCopyItem,
                              currentKey: _vm.currentKey,
                              pasteSource: _vm.pasteSource,
                            },
                            on: {
                              changeCurrNode: _vm.changeCurrNode,
                              removeItem: _vm.removeTabItem,
                              copyItem: _vm.copyTabItem,
                              choose: _vm.chooseDrag,
                              changePasteSource: _vm.changePasteSource,
                            },
                          })
                        : _c("component-item", {
                            key: col.key,
                            ref: `cpt_${col.key}`,
                            refInFor: true,
                            staticClass: "custom-form-item",
                            class: {
                              "is-checked": _vm.currentKey == col.key,
                              "copy-move": col.isCopyMove,
                            },
                            style: _vm.handleItemWidth(col),
                            attrs: {
                              index: cIndex,
                              data: col,
                              put: _vm.put,
                              isDrag: _vm.isDrag,
                            },
                            on: {
                              removeItem: _vm.removeTabItem,
                              copyItem: _vm.copyTabItem,
                              changeCurrNode: _vm.changeCurrNode,
                            },
                            nativeOn: {
                              click: function ($event) {
                                return _vm.changeCurrNode(col, $event)
                              },
                            },
                          }),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.actionVisible,
              expression: "actionVisible",
            },
          ],
          staticClass: "action-item",
        },
        [
          _c("i", {
            staticClass: "el-icon-copy-document",
            staticStyle: { "margin-right": "5px" },
            attrs: { title: "复制" },
            on: {
              click: _vm.copyItem,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          }),
          _c("i", {
            staticClass: "el-icon-delete",
            attrs: { title: "删除" },
            on: {
              click: _vm.removeItem,
              mousedown: function ($event) {
                $event.stopPropagation()
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }